import { AdyenPaymentMethodTypeEnum } from "types/graphql";

export type PaymentMethodType = Exclude<
  AdyenPaymentMethodTypeEnum,
  AdyenPaymentMethodTypeEnum.CupCard | AdyenPaymentMethodTypeEnum.DiscoverCard
>;

type MethodFeeRate = Record<PaymentMethodType, number | null>;

export const DEFAULT_ADYEN_PAYMENT_METHOD_FEE_RATE: MethodFeeRate = {
  [AdyenPaymentMethodTypeEnum.VisaCard]: 2.48,
  [AdyenPaymentMethodTypeEnum.MasterCard]: 2.48,
  [AdyenPaymentMethodTypeEnum.JcbCard]: 3.1,
  [AdyenPaymentMethodTypeEnum.AmexCard]: null,
  [AdyenPaymentMethodTypeEnum.DinersCard]: 3.1,
};

export const DEFAULT_ADYEN_TERMINAL_PAYMENT_METHOD_FEE_RATE: MethodFeeRate = {
  [AdyenPaymentMethodTypeEnum.VisaCard]: 2.48,
  [AdyenPaymentMethodTypeEnum.MasterCard]: 2.48,
  [AdyenPaymentMethodTypeEnum.JcbCard]: 3.1,
  // NOTE: Amex との契約が終わっていないためデフォルト料率が決まっていない
  [AdyenPaymentMethodTypeEnum.AmexCard]: 3.1,
  [AdyenPaymentMethodTypeEnum.DinersCard]: 3.1,
};

export const DEFAULT_ADYEN_SMALL_AND_MEDIUM_ENTERPRISE_TERMINAL_PAYMENT_METHOD_FEE_RATE: MethodFeeRate =
  {
    [AdyenPaymentMethodTypeEnum.VisaCard]: 1.888,
    [AdyenPaymentMethodTypeEnum.MasterCard]: 1.888,
    [AdyenPaymentMethodTypeEnum.JcbCard]: 2.38,
    // NOTE: Amex との契約が終わっていないためデフォルト料率が決まっていない
    [AdyenPaymentMethodTypeEnum.AmexCard]: 2.38,
    [AdyenPaymentMethodTypeEnum.DinersCard]: 2.38,
  };
