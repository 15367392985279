import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Alert, Flex, Modal, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { gold, red } from "@ant-design/colors";
import { CloseCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";

import { Spacer } from "components/Spacer";

import { AdyenStoreMerchantCategoryChangeRequestData } from "../useAdyenStoreMerchantCategoryChangeRequestForm";

type Props = {
  visible: boolean;
  adyenStoreMerchantCategoryChangeRequestData: AdyenStoreMerchantCategoryChangeRequestData[];
  loadingSubmitForm: boolean;
  onSubmitForm: () => void;
  onClose: () => void;
};

const StyledCloseCircleFilled = styled(CloseCircleFilled)`
  color: ${red[5]};
`;

const StyledExclamationCircleOutlined = styled(ExclamationCircleFilled)`
  color: ${gold[5]};
`;

const columns: ColumnsType<AdyenStoreMerchantCategoryChangeRequestData> = [
  {
    title: "ShopId",
    dataIndex: "shopId",
    key: "shopId",
    render: (shopId, { shopName, hasNonDefaultFeeRate }) => (
      <Flex align="center" gap={4}>
        {shopName === null ? (
          <StyledCloseCircleFilled />
        ) : hasNonDefaultFeeRate ? (
          <StyledExclamationCircleOutlined />
        ) : null}
        {shopId}
      </Flex>
    ),
  },
  {
    title: "店舗名",
    dataIndex: "shopName",
    key: "shopName",
  },
  {
    title: "MCC",
    dataIndex: "MCC",
    key: "MCC",
  },
  {
    title: "Visa料率",
    dataIndex: "Visa料率",
    key: "Visa料率",
    render: (value) => (value ? `${value}%` : "-"),
  },
  {
    title: "Mastercard料率",
    dataIndex: "Mastercard料率",
    key: "Mastercard料率",
    render: (value) => (value ? `${value}%` : "-"),
  },
  {
    title: "JCB料率",
    dataIndex: "JCB料率",
    key: "JCB料率",
    render: (value) => (value ? `${value}%` : "-"),
  },
  {
    title: "Amex料率",
    dataIndex: "Amex料率",
    key: "Amex料率",
    render: (value) => (value ? `${value}%` : "-"),
  },
  {
    title: "Diners料率",
    dataIndex: "Diners料率",
    key: "Diners料率",
    render: (value) => (value ? `${value}%` : "-"),
  },
];

export const AdyenStoreMerchantCategoryChangeRequestVerifyModal = memo<Props>(
  ({
    visible,
    adyenStoreMerchantCategoryChangeRequestData,
    loadingSubmitForm,
    onSubmitForm,
    onClose,
  }) => {
    const hasNonDefaultFeeRate = useMemo(
      () =>
        adyenStoreMerchantCategoryChangeRequestData.some(
          ({ hasNonDefaultFeeRate }) => hasNonDefaultFeeRate,
        ),
      [adyenStoreMerchantCategoryChangeRequestData],
    );

    const hasNullShopName = useMemo(
      () => adyenStoreMerchantCategoryChangeRequestData.some(({ shopName }) => shopName === null),
      [adyenStoreMerchantCategoryChangeRequestData],
    );

    return (
      <Modal
        title="編集内容の確認"
        open={visible}
        width="100%"
        onCancel={onClose}
        okText="更新"
        onOk={onSubmitForm}
        okButtonProps={{
          disabled: hasNullShopName,
        }}
        confirmLoading={loadingSubmitForm}
      >
        {hasNullShopName ? (
          <>
            <Alert type="error" message="店舗が存在しない shopId があります" showIcon />
            <Spacer size={24} />
          </>
        ) : hasNonDefaultFeeRate ? (
          <>
            <Alert type="warning" message="デフォルトの料率と異なるデータが存在します" showIcon />
            <Spacer size={24} />
          </>
        ) : null}
        <Table dataSource={adyenStoreMerchantCategoryChangeRequestData} columns={columns} />
      </Modal>
    );
  },
);
