import React, { memo } from "react";
import styled from "styled-components";
import { Modal } from "antd";

import { AccessibleCompanies, AccessibleShops } from "components/Layout/DashboardLayout/types";
import { colors } from "constants/colors";
import { viewport } from "constants/viewport";
import { useViewport } from "hooks/useViewport";

import { AvailableFrom } from "../Row/AvailableFrom";
import { Category } from "../Row/Category";
import { Message } from "../Row/Message";
import { Targets } from "../Row/Targets";
import { Title } from "../Row/Title";
import { TransformNotification } from "..";

const StyledModal = styled(Modal)<{ isDesktop: boolean }>`
  padding: 0px;
  .ant-modal-content {
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-modal-body {
    height: 100%;
    overflow-y: scroll;
  }

  @media ${viewport.desktop} {
    height: calc(100vh - 180px);
  }

  @media ${viewport.tablet} {
    top: 0;
    height: calc(100vh - 32px);
    margin: 16px;
  }

  @media ${viewport.smartphone} {
    top: 0;
    height: calc(85vh - 32px);
    margin: 16px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const MessageContainer = styled.div<{ isDesktop: boolean }>`
  line-height: 1.25;
  overflow-y: scroll;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${colors.Text.Primary};
  cursor: pointer;
  font-weight: 400;
`;

type BaseProps = {
  notification: TransformNotification;
  accountAccessibleCompanies: AccessibleCompanies;
  accountAccessibleShops: AccessibleShops;
  onClose: () => void;
  handleClose?: () => void;
  disableMaskClosable?: boolean;
};

export const NotificationModal = memo<BaseProps>(
  ({
    notification,
    onClose,
    accountAccessibleCompanies,
    accountAccessibleShops,
    handleClose,
    disableMaskClosable,
  }) => {
    const { isDesktop } = useViewport();

    const title = (
      <HeaderContainer>
        <Category isImportant={notification.isImportant} category={notification.category} />
        <Title
          title={notification.title}
          fontSize={16}
          fontWeight={notification.isUnread ? "bold" : "normal"}
        />
        <Targets
          targets={notification.targets}
          accountAccessibleCompanies={accountAccessibleCompanies}
          accountAccessibleShops={accountAccessibleShops}
        />
        <AvailableFrom availableFrom={notification.availableFrom} />
      </HeaderContainer>
    );

    const footer = (
      <FooterContainer>
        <CloseButton onClick={handleClose}>閉じる</CloseButton>
      </FooterContainer>
    );

    return (
      <StyledModal
        zIndex={1003}
        open={Boolean(notification)}
        title={title}
        onCancel={onClose}
        width={isDesktop ? "800px" : "calc(100vw - 32px)"}
        isDesktop={isDesktop}
        footer={footer}
        maskClosable={!disableMaskClosable}
      >
        <MessageContainer isDesktop={isDesktop}>
          <Message message={notification.message} />
        </MessageContainer>
      </StyledModal>
    );
  },
);
