import React, { memo } from "react";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";

type Props = {
  onSelectScheduledDate: (date: Dayjs | null) => void;
};

export const AdyenStoreMerchantCategoryChangeRequestScheduleDateField = memo<Props>(
  ({ onSelectScheduledDate }) => (
    <FormItem label="反映日" required>
      <DatePicker
        disabledDate={(date) => date.isBefore(dayjs().add(1, "day"), "day")}
        onChange={onSelectScheduledDate}
      />
    </FormItem>
  ),
);
