import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPlanPikaichiPlanFormItem } from "../useEditPlanPikaichiPlanForm";
import { PikaichiCodeAndName } from "..";

type Props = Omit<FormItemProps, "children" | "name"> & {
  setPikaichiPlanCodeAndName: React.Dispatch<React.SetStateAction<PikaichiCodeAndName>>;
};

export const PikaichiMenuNameField = memo<Props>(({ setPikaichiPlanCodeAndName, ...props }) => (
  <EditPlanPikaichiPlanFormItem
    label="メニュー名称"
    name="pikaichiMenuName"
    rules={[{ required: true, max: 25, message: "25文字以内で入力してください" }]}
    {...props}
  >
    <Input
      onChange={(event) =>
        setPikaichiPlanCodeAndName((p) => ({ ...p, pikaichiMenuName: event.target.value }))
      }
    />
  </EditPlanPikaichiPlanFormItem>
));
