import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AdyenTerminalPaymentHistoryGetAuthorizedEvents = gql`
    query AdyenTerminalPaymentHistoryGetAuthorizedEvents($shopId: uuid!, $since: timestamptz!, $until: timestamptz) {
  adyenPaymentReportTerminalPaymentAuthorizedEvent(
    where: {shopId: {_eq: $shopId}, createdAt: {_gte: $since, _lt: $until}}
    order_by: {createdAt: asc}
  ) {
    id
    createdAt
    amount
    transactionFeeRate
    netAmount
    feeAmount
    paymentMethod
    pspReference
    adyenPaymentReportTerminalPaymentCanceledEvent {
      id
      createdAt
    }
  }
}
    `;
export const AdyenTerminalPaymentHistoryGetPayoutJobSchedules = gql`
    query AdyenTerminalPaymentHistoryGetPayoutJobSchedules($sinceDate: date!, $untilDate: date!) {
  payoutJobSchedule(where: {closingDate: {_gte: $sinceDate, _lt: $untilDate}}) {
    id
    closingDate
    depositCycle
  }
}
    `;
export const AdyenTerminalPaymentHistoryGetGmoBankAccountShop = gql`
    query AdyenTerminalPaymentHistoryGetGmoBankAccountShop($shopId: uuid!) {
  gmoBankAccountShop(where: {shopId: {_eq: $shopId}}) {
    depositCycleType
  }
}
    `;
export type AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  since: Types.Scalars['timestamptz'];
  until?: Types.InputMaybe<Types.Scalars['timestamptz']>;
}>;


export type AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery = (
  { __typename?: 'query_root' }
  & { adyenPaymentReportTerminalPaymentAuthorizedEvent: Array<(
    { __typename?: 'adyenPaymentReportTerminalPaymentAuthorizedEvent' }
    & Pick<Types.AdyenPaymentReportTerminalPaymentAuthorizedEvent, 'id' | 'createdAt' | 'amount' | 'transactionFeeRate' | 'netAmount' | 'feeAmount' | 'paymentMethod' | 'pspReference'>
    & { adyenPaymentReportTerminalPaymentCanceledEvent?: Types.Maybe<(
      { __typename?: 'adyenPaymentReportTerminalPaymentCanceledEvent' }
      & Pick<Types.AdyenPaymentReportTerminalPaymentCanceledEvent, 'id' | 'createdAt'>
    )> }
  )> }
);

export type AdyenTerminalPaymentHistoryGetPayoutJobSchedulesQueryVariables = Types.Exact<{
  sinceDate: Types.Scalars['date'];
  untilDate: Types.Scalars['date'];
}>;


export type AdyenTerminalPaymentHistoryGetPayoutJobSchedulesQuery = (
  { __typename?: 'query_root' }
  & { payoutJobSchedule: Array<(
    { __typename?: 'payoutJobSchedule' }
    & Pick<Types.PayoutJobSchedule, 'id' | 'closingDate' | 'depositCycle'>
  )> }
);

export type AdyenTerminalPaymentHistoryGetGmoBankAccountShopQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type AdyenTerminalPaymentHistoryGetGmoBankAccountShopQuery = (
  { __typename?: 'query_root' }
  & { gmoBankAccountShop: Array<(
    { __typename?: 'gmoBankAccountShop' }
    & Pick<Types.GmoBankAccountShop, 'depositCycleType'>
  )> }
);


export const AdyenTerminalPaymentHistoryGetAuthorizedEventsDocument = gql`
    query AdyenTerminalPaymentHistoryGetAuthorizedEvents($shopId: uuid!, $since: timestamptz!, $until: timestamptz) {
  adyenPaymentReportTerminalPaymentAuthorizedEvent(
    where: {shopId: {_eq: $shopId}, createdAt: {_gte: $since, _lt: $until}}
    order_by: {createdAt: asc}
  ) {
    id
    createdAt
    amount
    transactionFeeRate
    netAmount
    feeAmount
    paymentMethod
    pspReference
    adyenPaymentReportTerminalPaymentCanceledEvent {
      id
      createdAt
    }
  }
}
    `;

/**
 * __useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery__
 *
 * To run a query within a React component, call `useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      since: // value for 'since'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery(baseOptions: Apollo.QueryHookOptions<AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery, AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery, AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryVariables>(AdyenTerminalPaymentHistoryGetAuthorizedEventsDocument, options);
      }
export function useAdyenTerminalPaymentHistoryGetAuthorizedEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery, AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery, AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryVariables>(AdyenTerminalPaymentHistoryGetAuthorizedEventsDocument, options);
        }
export type AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryHookResult = ReturnType<typeof useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery>;
export type AdyenTerminalPaymentHistoryGetAuthorizedEventsLazyQueryHookResult = ReturnType<typeof useAdyenTerminalPaymentHistoryGetAuthorizedEventsLazyQuery>;
export type AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryResult = Apollo.QueryResult<AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery, AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryVariables>;
export const AdyenTerminalPaymentHistoryGetPayoutJobSchedulesDocument = gql`
    query AdyenTerminalPaymentHistoryGetPayoutJobSchedules($sinceDate: date!, $untilDate: date!) {
  payoutJobSchedule(where: {closingDate: {_gte: $sinceDate, _lt: $untilDate}}) {
    id
    closingDate
    depositCycle
  }
}
    `;

/**
 * __useAdyenTerminalPaymentHistoryGetPayoutJobSchedulesQuery__
 *
 * To run a query within a React component, call `useAdyenTerminalPaymentHistoryGetPayoutJobSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdyenTerminalPaymentHistoryGetPayoutJobSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdyenTerminalPaymentHistoryGetPayoutJobSchedulesQuery({
 *   variables: {
 *      sinceDate: // value for 'sinceDate'
 *      untilDate: // value for 'untilDate'
 *   },
 * });
 */
export function useAdyenTerminalPaymentHistoryGetPayoutJobSchedulesQuery(baseOptions: Apollo.QueryHookOptions<AdyenTerminalPaymentHistoryGetPayoutJobSchedulesQuery, AdyenTerminalPaymentHistoryGetPayoutJobSchedulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdyenTerminalPaymentHistoryGetPayoutJobSchedulesQuery, AdyenTerminalPaymentHistoryGetPayoutJobSchedulesQueryVariables>(AdyenTerminalPaymentHistoryGetPayoutJobSchedulesDocument, options);
      }
export function useAdyenTerminalPaymentHistoryGetPayoutJobSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdyenTerminalPaymentHistoryGetPayoutJobSchedulesQuery, AdyenTerminalPaymentHistoryGetPayoutJobSchedulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdyenTerminalPaymentHistoryGetPayoutJobSchedulesQuery, AdyenTerminalPaymentHistoryGetPayoutJobSchedulesQueryVariables>(AdyenTerminalPaymentHistoryGetPayoutJobSchedulesDocument, options);
        }
export type AdyenTerminalPaymentHistoryGetPayoutJobSchedulesQueryHookResult = ReturnType<typeof useAdyenTerminalPaymentHistoryGetPayoutJobSchedulesQuery>;
export type AdyenTerminalPaymentHistoryGetPayoutJobSchedulesLazyQueryHookResult = ReturnType<typeof useAdyenTerminalPaymentHistoryGetPayoutJobSchedulesLazyQuery>;
export type AdyenTerminalPaymentHistoryGetPayoutJobSchedulesQueryResult = Apollo.QueryResult<AdyenTerminalPaymentHistoryGetPayoutJobSchedulesQuery, AdyenTerminalPaymentHistoryGetPayoutJobSchedulesQueryVariables>;
export const AdyenTerminalPaymentHistoryGetGmoBankAccountShopDocument = gql`
    query AdyenTerminalPaymentHistoryGetGmoBankAccountShop($shopId: uuid!) {
  gmoBankAccountShop(where: {shopId: {_eq: $shopId}}) {
    depositCycleType
  }
}
    `;

/**
 * __useAdyenTerminalPaymentHistoryGetGmoBankAccountShopQuery__
 *
 * To run a query within a React component, call `useAdyenTerminalPaymentHistoryGetGmoBankAccountShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdyenTerminalPaymentHistoryGetGmoBankAccountShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdyenTerminalPaymentHistoryGetGmoBankAccountShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAdyenTerminalPaymentHistoryGetGmoBankAccountShopQuery(baseOptions: Apollo.QueryHookOptions<AdyenTerminalPaymentHistoryGetGmoBankAccountShopQuery, AdyenTerminalPaymentHistoryGetGmoBankAccountShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdyenTerminalPaymentHistoryGetGmoBankAccountShopQuery, AdyenTerminalPaymentHistoryGetGmoBankAccountShopQueryVariables>(AdyenTerminalPaymentHistoryGetGmoBankAccountShopDocument, options);
      }
export function useAdyenTerminalPaymentHistoryGetGmoBankAccountShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdyenTerminalPaymentHistoryGetGmoBankAccountShopQuery, AdyenTerminalPaymentHistoryGetGmoBankAccountShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdyenTerminalPaymentHistoryGetGmoBankAccountShopQuery, AdyenTerminalPaymentHistoryGetGmoBankAccountShopQueryVariables>(AdyenTerminalPaymentHistoryGetGmoBankAccountShopDocument, options);
        }
export type AdyenTerminalPaymentHistoryGetGmoBankAccountShopQueryHookResult = ReturnType<typeof useAdyenTerminalPaymentHistoryGetGmoBankAccountShopQuery>;
export type AdyenTerminalPaymentHistoryGetGmoBankAccountShopLazyQueryHookResult = ReturnType<typeof useAdyenTerminalPaymentHistoryGetGmoBankAccountShopLazyQuery>;
export type AdyenTerminalPaymentHistoryGetGmoBankAccountShopQueryResult = Apollo.QueryResult<AdyenTerminalPaymentHistoryGetGmoBankAccountShopQuery, AdyenTerminalPaymentHistoryGetGmoBankAccountShopQueryVariables>;