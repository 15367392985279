import React, { useCallback, useMemo, useState } from "react";
import { Badge, Button, Form, Popconfirm, Select, Table as AntdTable, Typography } from "antd";
import { isEqual, omit } from "lodash";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { FormActions } from "components/Form/FormActions";
import { InputCode } from "components/Input/InputCode";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { ConfirmationModal } from "../ConfirmationModal";
import { OnSitePaymentDetailType } from "../types";

const { Paragraph } = Typography;

export const foodingJournalPaymentTypes: { paymentType: number; name: string }[] = [
  {
    paymentType: 0,
    name: "現金",
  },
  {
    paymentType: 1,
    name: "クレジット",
  },
  {
    paymentType: 2,
    name: "電子マネー",
  },
  {
    paymentType: 3,
    name: "売掛",
  },
  {
    paymentType: 4,
    name: "商品券 (釣無し)",
  },
  {
    paymentType: 5,
    name: "商品券 (釣有り",
  },
];

export type FoodingJournalOnSitePaymentDetailTypeSource = {
  paymentType: string | undefined;
  code: string | undefined;
  name: string | undefined;
};

export type NonNullableFoodingJournalOnSitePaymentDetailTypeSource = {
  paymentType: string;
  code: string;
  name: string;
};

type RowItem = {
  id: string;
  label: string;
  foodingJournalOnSitePaymentDetailTypeId: string | undefined;
  paymentType: string | undefined;
  code: string | undefined;
  name: string | undefined;
};

type Props = {
  loading: boolean;
  onSitePaymentDetailTypes: OnSitePaymentDetailType[];
  refetchTypes: () => Promise<void>;
  upsertFoodingJournalOnSitePaymentDetailTypes: (args: {
    types: {
      onSitePaymentDetailTypeId: string;
      foodingJournalOnSitePaymentDetailTypeId: string | undefined;
      paymentType: number;
      code: string;
      name: string;
    }[];
  }) => Promise<void>;
  deleteFoodingJournalOnSitePaymentDetailType: (args: {
    foodingJournalOnSitePaymentDetailTypeId: string;
  }) => Promise<void>;
};

const isEditingValueEmpty = ({
  paymentType,
  code,
  name,
}: FoodingJournalOnSitePaymentDetailTypeSource) => !code && !name && !paymentType;

const isCodesValid = <T extends FoodingJournalOnSitePaymentDetailTypeSource>(
  rows: T[],
): rows is (T & NonNullableFoodingJournalOnSitePaymentDetailTypeSource)[] =>
  rows.every(
    (row) => (row.paymentType === "0" || Boolean(row.code)) && Boolean(row.paymentType && row.name),
  );

const { Column } = AntdTable;

export const OnSitePaymentDetailTypeTable = React.memo<Props>(
  ({
    loading,
    onSitePaymentDetailTypes,
    refetchTypes,
    upsertFoodingJournalOnSitePaymentDetailTypes,
    deleteFoodingJournalOnSitePaymentDetailType,
  }) => {
    const [form] = Form.useForm();
    const [pagination, setPagination] = usePagination();

    const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false);

    const [editingOnSitePaymentDetailTypes, setEditingOnSitePaymentDetailTypes] = useState<
      Record<
        string,
        FoodingJournalOnSitePaymentDetailTypeSource & {
          foodingJournalOnSitePaymentDetailTypeId: string | undefined;
        }
      >
    >({});

    const dataSources = useMemo<RowItem[]>(
      () =>
        onSitePaymentDetailTypes.map((detail) => ({
          id: detail.id,
          label: detail.label,
          foodingJournalOnSitePaymentDetailTypeId: detail.foodingJournalOnSitePaymentDetailType?.id,
          paymentType: detail.foodingJournalOnSitePaymentDetailType?.paymentType.toString(),
          code: detail.foodingJournalOnSitePaymentDetailType?.code,
          name: detail.foodingJournalOnSitePaymentDetailType?.name,
        })),
      [onSitePaymentDetailTypes],
    );

    const openModal = useCallback(() => setShouldShowConfirmationModal(true), []);

    const closeModal = useCallback(() => setShouldShowConfirmationModal(false), []);

    const handleChange = useCallback(
      ({ rowItem }: { rowItem: RowItem }) => {
        const editingValue = (
          form.getFieldsValue() as Record<string, FoodingJournalOnSitePaymentDetailTypeSource>
        )[rowItem.id];

        if (!editingValue) return;

        const mergedValue = {
          foodingJournalOnSitePaymentDetailTypeId: rowItem.foodingJournalOnSitePaymentDetailTypeId,
          paymentType: editingValue.paymentType ?? rowItem.paymentType,
          code: editingValue.paymentType !== "0" ? editingValue.code ?? rowItem.code : "",
          name: editingValue.name ?? rowItem.name,
        };

        if (
          isEditingValueEmpty(editingValue) ||
          isEqual(mergedValue, {
            foodingJournalOnSitePaymentDetailTypeId:
              rowItem.foodingJournalOnSitePaymentDetailTypeId,
            paymentType: rowItem.paymentType,
            code: rowItem.code,
            name: rowItem.name,
          })
        ) {
          return setEditingOnSitePaymentDetailTypes((m) => omit(m, rowItem.id));
        }

        setEditingOnSitePaymentDetailTypes((m) => ({ ...m, [rowItem.id]: mergedValue }));

        form.setFieldValue(rowItem.id, mergedValue);
      },
      [form],
    );

    const handleConfirm = useCallback(async () => {
      try {
        await form.validateFields(
          Object.entries(editingOnSitePaymentDetailTypes).map(([id]) => [id]),
          { recursive: true },
        );
      } catch {
        return;
      }

      openModal();
    }, [editingOnSitePaymentDetailTypes, form, openModal]);

    const handleClear = useCallback(() => {
      setEditingOnSitePaymentDetailTypes({});

      form.resetFields();
    }, [form]);

    const handleSubmit = useCallback(async () => {
      const types = Object.entries(editingOnSitePaymentDetailTypes).map(([id, type]) => ({
        onSitePaymentDetailTypeId: id,
        foodingJournalOnSitePaymentDetailTypeId: type.foodingJournalOnSitePaymentDetailTypeId,
        paymentType: type.paymentType,
        code: type.code,
        name: type.name,
      }));

      if (!isCodesValid(types)) return;

      await upsertFoodingJournalOnSitePaymentDetailTypes({
        types: types.map((type) => ({ ...type, paymentType: Number(type.paymentType) })),
      });

      await refetchTypes();

      form.resetFields();

      setEditingOnSitePaymentDetailTypes({});

      closeModal();
    }, [
      closeModal,
      editingOnSitePaymentDetailTypes,
      form,
      refetchTypes,
      upsertFoodingJournalOnSitePaymentDetailTypes,
    ]);

    const handleDelete = useCallback(
      async ({ rowItem }: { rowItem: RowItem }) => {
        if (rowItem.foodingJournalOnSitePaymentDetailTypeId) {
          await deleteFoodingJournalOnSitePaymentDetailType({
            foodingJournalOnSitePaymentDetailTypeId:
              rowItem.foodingJournalOnSitePaymentDetailTypeId,
          });
          await refetchTypes();
        }

        setEditingOnSitePaymentDetailTypes((m) => omit(m, rowItem.id));

        form.resetFields([
          [rowItem.id, "paymentType"],
          [rowItem.id, "code"],
          [rowItem.id, "name"],
        ]);
      },
      [deleteFoodingJournalOnSitePaymentDetailType, form, refetchTypes],
    );

    const isCashSelected = ({ rowItem }: { rowItem: RowItem }) =>
      editingOnSitePaymentDetailTypes[rowItem.id]?.paymentType === "0" ||
      (rowItem.paymentType === "0" && !editingOnSitePaymentDetailTypes[rowItem.id]);

    return (
      <>
        <Form form={form} component={false}>
          <Table
            rowKey={(rowItem: RowItem) => rowItem.id}
            dataSource={dataSources}
            loading={loading}
            bordered
            pagination={pagination}
            onChange={({ position: _, ...pagination }) => setPagination(pagination)}
            footer={() => (
              <>
                <FormActions>
                  <Button onClick={handleClear}>クリア</Button>

                  <Badge count={Object.keys(editingOnSitePaymentDetailTypes).length}>
                    <Button
                      type="primary"
                      disabled={Object.keys(editingOnSitePaymentDetailTypes).length === 0}
                      onClick={handleConfirm}
                    >
                      確認
                    </Button>
                  </Badge>
                </FormActions>
              </>
            )}
          >
            <Column dataIndex="label" title="会計種別" width={200} />

            <Column
              dataIndex="paymentType"
              title="支払種別"
              align="left"
              render={(_: string, rowItem: RowItem) => (
                <FormItem
                  style={{ width: 300 }}
                  key={rowItem.id}
                  name={[rowItem.id, "paymentType"]}
                  rules={[
                    {
                      required: true,
                      message: "支払種別を選択してください",
                    },
                  ]}
                >
                  <Select<string>
                    defaultValue={rowItem.paymentType}
                    placeholder="支払種別"
                    onSelect={() => handleChange({ rowItem })}
                  >
                    {foodingJournalPaymentTypes.map(({ paymentType, name }) => (
                      <Select.Option key={paymentType} value={paymentType.toString()}>
                        {`${paymentType}: ${name}`}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              )}
            />

            <Column
              dataIndex="code"
              title="支払コード"
              align="left"
              render={(_: string, rowItem: RowItem) => (
                <FormItem
                  style={{ width: 300 }}
                  key={rowItem.id}
                  name={[rowItem.id, "code"]}
                  rules={[
                    {
                      required: !isCashSelected({ rowItem }),
                      pattern: /^\d{1,3}$/,
                      message: `3桁以内の数字で入力してください`,
                    },
                  ]}
                >
                  <InputCode
                    defaultValue={rowItem.code}
                    placeholder="支払コード"
                    onBlur={() => handleChange({ rowItem })}
                    disabled={isCashSelected({ rowItem })}
                  />
                </FormItem>
              )}
            />

            <Column
              dataIndex="name"
              title="支払名称"
              align="left"
              render={(_: string, rowItem: RowItem) => (
                <FormItem
                  style={{ width: 300 }}
                  key={rowItem.id}
                  name={[rowItem.id, "name"]}
                  rules={[
                    {
                      required: true,
                      min: 1,
                      max: 20,
                      message: `20桁以下の文字列を入力してください`,
                    },
                  ]}
                >
                  <InputCode
                    defaultValue={rowItem.name}
                    placeholder="支払名称"
                    onBlur={() => handleChange({ rowItem })}
                  />
                </FormItem>
              )}
            />

            <Column
              title=""
              align="left"
              width="50"
              render={(_: string, rowItem: RowItem) => (
                <>
                  {rowItem.foodingJournalOnSitePaymentDetailTypeId ? (
                    <Popconfirm
                      title={
                        <>
                          <Paragraph>設定を削除しますか？</Paragraph>
                          <Paragraph>一度削除した設定を元に戻すことはできません。</Paragraph>
                        </>
                      }
                      okText="はい"
                      cancelText="キャンセル"
                      onConfirm={() => {
                        handleDelete({ rowItem });
                      }}
                    >
                      <DeleteIcon />
                    </Popconfirm>
                  ) : (
                    <DeleteIcon onClick={() => handleDelete({ rowItem })} />
                  )}
                </>
              )}
            />
          </Table>
        </Form>

        {shouldShowConfirmationModal && (
          <ConfirmationModal
            editingOnSitePaymentDetailTypes={editingOnSitePaymentDetailTypes}
            onSitePaymentDetailTypes={onSitePaymentDetailTypes}
            loading={loading}
            onSubmit={handleSubmit}
            onCancel={closeModal}
          />
        )}
      </>
    );
  },
);
