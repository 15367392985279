import React, { memo, useCallback, useState } from "react";
import { Button, Col, Row } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { PikaichiMenuInsertInput } from "types/graphql";

import { PikaichiMenu, Plan } from "../types";

import { PikaichiBumonCdField } from "./PikaichiBumonCdField";
import { PikaichiBumonNameField } from "./PikaichiBumonNameField";
import { PikaichiCategoryCdField } from "./PikaichiCategoryCdField";
import { PikaichiCategoryNameField } from "./PikaichiCategoryNameField";
import { PikaichiGetAvailableMenuCdButtonField } from "./PikaichiGetAvailableMenuCdButtonField";
import { PikaichiMenuCdField } from "./PikaichiMenuCdField";
import { PikaichiMenuNameField } from "./PikaichiMenuNameField";
import { PikaichiPlanOptionTable } from "./PikaichiPlanOptionTable";
import { useEditPlanPikaichiPlanForm } from "./useEditPlanPikaichiPlanForm";

type Props = {
  plan: Plan;
  pikaichiMenus: Pick<PikaichiMenu, "pikaichiMenuId" | "pikaichiMenuName" | "pikaichiMenuCd">[];
  onSubmit: (args: Omit<PikaichiMenuInsertInput, "companyId">) => void;
  onClose: () => void;
  onGetAvailableMenuCodeButtonPressed: () => number;
  loading: boolean;
};

export type PikaichiCodeAndName = Pick<PikaichiMenu, "pikaichiMenuCd" | "pikaichiMenuName"> & {
  id: string;
};

export const EditPlanPikaichiPlanForm = memo<Props>(
  ({ plan, pikaichiMenus, onClose, onSubmit, onGetAvailableMenuCodeButtonPressed, loading }) => {
    const pikaichiMenu = plan?.pikaichiMenuPlans?.[0]?.pikaichiMenu;
    const pikaichiMenuId = pikaichiMenu?.pikaichiMenuId;
    const [pikaichiPlanCodeAndName, setPikaichiPlanCodeAndName] = useState<PikaichiCodeAndName>({
      id: plan?.id,
      pikaichiMenuCd: pikaichiMenu?.pikaichiMenuCd ?? "",
      pikaichiMenuName: pikaichiMenu?.pikaichiMenuName ?? plan?.planName,
    });
    const [pikaichiPlanChoicesCodeAndName, setPikaichiPlanChoicesCodeAndName] = useState<
      PikaichiCodeAndName[]
    >(
      plan?.planOptions?.flatMap((planOption) =>
        planOption.planChoices.map((planChoice) => ({
          id: planChoice.id,
          pikaichiMenuCd:
            planChoice.pikaichiMenuPlanChoices?.[0]?.pikaichiMenu.pikaichiMenuCd ?? "",
          pikaichiMenuName:
            planChoice.pikaichiMenuPlanChoices?.[0]?.pikaichiMenu.pikaichiMenuName ??
            planChoice.name,
        })),
      ),
    );

    const { form, initialValues, submit } = useEditPlanPikaichiPlanForm({
      plan,
      pikaichiMenu,
      onSubmit,
    });

    const handleGetAvailableMenuCodeButtonPressed = useCallback(() => {
      const maxMenuCode = onGetAvailableMenuCodeButtonPressed();
      form.setFields([{ name: "pikaichiMenuCd", value: String(maxMenuCode) }]);
    }, [form, onGetAvailableMenuCodeButtonPressed]);

    const handleGetAvailableMenuCodeForOptionButtonPressed = useCallback(() => {
      const fieldsValue = form.getFieldsValue();

      let maxMenuCode = onGetAvailableMenuCodeButtonPressed();

      fieldsValue.plan?.planOptions.forEach((planOption, planOptionIndex) => {
        planOption.planChoices.forEach((planChoice, planChoiceIndex) => {
          if (planChoice.pikaichiMenuPlanChoices?.[0]?.pikaichiMenu.pikaichiMenuCd) {
            return;
          }

          const fieldPath = [
            "plan",
            "planOptions",
            planOptionIndex,
            "planChoices",
            planChoiceIndex,
            "pikaichiMenuPlanChoices",
            0,
            "pikaichiMenu",
            "pikaichiMenuCd",
          ];

          form.setFieldValue(fieldPath, (maxMenuCode++).toString());
        });
      });
    }, [form, onGetAvailableMenuCodeButtonPressed]);

    return (
      <>
        <FormContent>
          <Form name="pikaichiMenu" form={form} initialValues={initialValues} layout="vertical">
            <Row gutter={24}>
              <Col flex={1}>
                <PikaichiMenuCdField
                  pikaichiMenus={pikaichiMenus}
                  pikaichiMenuId={pikaichiMenuId}
                  setPikaichiPlanCodeAndName={setPikaichiPlanCodeAndName}
                  pikaichiPlanChoicesCodeAndName={pikaichiPlanChoicesCodeAndName}
                />
              </Col>
              <Col>
                <PikaichiGetAvailableMenuCdButtonField
                  handleGetAvailableMenuCodeButtonPressed={handleGetAvailableMenuCodeButtonPressed}
                />
              </Col>
            </Row>
            <PikaichiMenuNameField setPikaichiPlanCodeAndName={setPikaichiPlanCodeAndName} />
            <PikaichiBumonCdField />
            <PikaichiBumonNameField />
            <PikaichiCategoryCdField />
            <PikaichiCategoryNameField />
            <PikaichiPlanOptionTable
              handleGetAvailableMenuCodeForOptionButtonPressed={
                handleGetAvailableMenuCodeForOptionButtonPressed
              }
              pikaichiMenus={pikaichiMenus}
              pikaichiPlanCodeAndName={pikaichiPlanCodeAndName}
              setPikaichiPlanChoicesCodeAndName={setPikaichiPlanChoicesCodeAndName}
            />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
