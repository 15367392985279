import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPlanWinboardPlanFormItem } from "../useEditPlanWinboardPlanForm";
import { WinboardCodeAndName } from "..";

type Props = Omit<FormItemProps, "children" | "name"> & {
  setWinboardPlanCodeAndName: React.Dispatch<React.SetStateAction<WinboardCodeAndName>>;
};

export const NameField = memo<Props>(({ setWinboardPlanCodeAndName, ...props }) => (
  <EditPlanWinboardPlanFormItem
    label="メニュー名称"
    name="name"
    rules={[{ max: 30, message: "30文字以内で入力してください" }]}
    {...props}
  >
    <Input
      onChange={(event) =>
        setWinboardPlanCodeAndName((prev) => ({ ...prev, name: event.target.value }))
      }
    />
  </EditPlanWinboardPlanFormItem>
));
