import React, { useMemo, useState } from "react";
import { Alert, Button } from "antd";
import { uniq } from "lodash";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { isUUID } from "libs/isUUID";

import { AdyenStoreMerchantCategoryChangeRequestModal } from "./AdyenStoreMerchantCategoryChangeRequestModal";
import {
  useShopCashlessSettingGetShopForAdyenStoreMerchantCategoryChangeRequestCsvQuery,
  useShopCashlessSettingGetShopQuery,
} from "./queries";
import { ShopTable } from "./ShopTable";

export const ShopCashlessSetting = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const [
    adyenStoreMerchantCategoryChangeRequestFormModalVisible,
    setAdyenStoreMerchantCategoryChangeRequestFormModalVisible,
  ] = useState(false);

  const {
    data: getShopsData,
    loading: loadingShops,
    error: getShopsDataError,
  } = useShopCashlessSettingGetShopQuery(companyId ? { variables: { companyId } } : { skip: true });
  const shops = useMemo(() => getShopsData?.shop ?? [], [getShopsData]);

  const [
    adyenStoreMerchantCategoryChangeRequestCsvShopIds,
    setAdyenStoreMerchantCategoryChangeRequestCsvShopIds,
  ] = useState<string[]>([]);
  const {
    data: getAdyenStoreMerchantCategoryChangeRequestCsvShopsData,
    error: getAdyenStoreMerchantCategoryChangeRequestCsvShopsError,
  } = useShopCashlessSettingGetShopForAdyenStoreMerchantCategoryChangeRequestCsvQuery({
    variables: { shopIds: uniq(adyenStoreMerchantCategoryChangeRequestCsvShopIds.filter(isUUID)) },
  });

  return (
    <DashboardLayout title="決済店舗">
      <PageHeader
        title="決済店舗"
        extra={
          <Button
            type="primary"
            onClick={() => setAdyenStoreMerchantCategoryChangeRequestFormModalVisible(true)}
          >
            一括項目編集
          </Button>
        }
      />

      {(getShopsDataError || getAdyenStoreMerchantCategoryChangeRequestCsvShopsError) && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <ShopTable shops={shops} loading={loadingShops} />

      <AdyenStoreMerchantCategoryChangeRequestModal
        key={adyenStoreMerchantCategoryChangeRequestFormModalVisible.toString()}
        shops={getAdyenStoreMerchantCategoryChangeRequestCsvShopsData?.shop ?? []}
        visible={adyenStoreMerchantCategoryChangeRequestFormModalVisible}
        setAdyenStoreMerchantCategoryChangeRequestCsvShopIds={
          setAdyenStoreMerchantCategoryChangeRequestCsvShopIds
        }
        onClose={() => setAdyenStoreMerchantCategoryChangeRequestFormModalVisible(false)}
      />
    </DashboardLayout>
  );
};
