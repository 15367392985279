import React, { memo } from "react";
import { Modal, Typography, UploadFile } from "antd";
import { Dayjs } from "dayjs";

import { Spacer } from "components/Spacer";

import { AdyenStoreMerchantCategoryChangeRequestFormValues } from "../useAdyenStoreMerchantCategoryChangeRequestForm";

import { AdyenStoreMerchantCategoryChangeRequestFileField } from "./AdyenStoreMerchantCategoryChangeRequestFileField";
import { AdyenStoreMerchantCategoryChangeRequestScheduleDateField } from "./AdyenStoreMerchantCategoryChangeRequestScheduleDateField";

type Props = {
  visible: boolean;
  adyenStoreMerchantCategoryChangeRequestFormValues: AdyenStoreMerchantCategoryChangeRequestFormValues;
  isValidAdyenStoreMerchantCategoryChangeRequest: boolean;
  loadingVerifyCsv: boolean;
  onSelectFiles: (files: UploadFile<any>) => void;
  removeUploadedFile: () => void;
  onSelectScheduledDate: (date: Dayjs | null) => void;
  onClose: () => void;
  onNextStep: () => void;
};

export const AdyenStoreMerchantCategoryChangeRequestFormModal = memo<Props>(
  ({
    visible,
    adyenStoreMerchantCategoryChangeRequestFormValues,
    isValidAdyenStoreMerchantCategoryChangeRequest,
    loadingVerifyCsv,
    onSelectFiles,
    removeUploadedFile,
    onSelectScheduledDate,
    onClose,
    onNextStep,
  }) => (
    <Modal
      title="一括項目編集（CSVインポート）"
      open={visible}
      onCancel={onClose}
      okText="編集内容の確認"
      okButtonProps={{
        disabled: !isValidAdyenStoreMerchantCategoryChangeRequest,
      }}
      onOk={onNextStep}
    >
      <Typography.Text>登録されている店舗のMCCと料率が変更可能です。</Typography.Text>
      <Spacer height={16} />
      <AdyenStoreMerchantCategoryChangeRequestFileField
        adyenStoreMerchantCategoryChangeRequestFile={
          adyenStoreMerchantCategoryChangeRequestFormValues.adyenStoreMerchantCategoryChangeRequestFile
        }
        loadingVerifyCsv={loadingVerifyCsv}
        onSelectFiles={onSelectFiles}
        removeUploadedFile={removeUploadedFile}
      />
      <Spacer height={16} />
      <AdyenStoreMerchantCategoryChangeRequestScheduleDateField
        onSelectScheduledDate={onSelectScheduledDate}
      />
    </Modal>
  ),
);
