import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { useAsyncFn } from "react-use";
import { UploadFile } from "antd";
import { AxiosError } from "axios";
import { Dayjs } from "dayjs";
import { keyBy } from "lodash";
import { isSmallAndMediumEnterpriseStoreMerchantCategory } from "models/adyenMerchantCategory";

import { message } from "components/antd/message";
import {
  DEFAULT_ADYEN_SMALL_AND_MEDIUM_ENTERPRISE_TERMINAL_PAYMENT_METHOD_FEE_RATE,
  DEFAULT_ADYEN_TERMINAL_PAYMENT_METHOD_FEE_RATE,
  PaymentMethodType,
} from "constants/adyenPaymentMethod";
import { useDinii } from "hooks/useDinii";
import { AdyenPaymentMethodTypeEnum } from "types/graphql";

import { ShopForAdyenStoreMerchantCategoryChangeRequestCsv } from "../types";

export type AdyenStoreMerchantCategoryChangeRequestFormValues = {
  adyenStoreMerchantCategoryChangeRequestFile: UploadFile<any> | null;
  scheduledDate: Dayjs | null;
};

type AdyenStoreMerchantCategoryChangeRequestCsvData = {
  shopId: string;
  MCC: string;
  Visa料率: number | null;
  Mastercard料率: number | null;
  JCB料率: number | null;
  Amex料率: number | null;
  Diners料率: number | null;
};

const adyenPaymentMethodsWithCsvColumn: {
  adyenPaymentMethod: PaymentMethodType;
  column: Exclude<keyof AdyenStoreMerchantCategoryChangeRequestCsvData, "shopId" | "MCC">;
}[] = [
  { adyenPaymentMethod: AdyenPaymentMethodTypeEnum.VisaCard, column: "Visa料率" },
  { adyenPaymentMethod: AdyenPaymentMethodTypeEnum.MasterCard, column: "Mastercard料率" },
  { adyenPaymentMethod: AdyenPaymentMethodTypeEnum.JcbCard, column: "JCB料率" },
  { adyenPaymentMethod: AdyenPaymentMethodTypeEnum.AmexCard, column: "Amex料率" },
  { adyenPaymentMethod: AdyenPaymentMethodTypeEnum.DinersCard, column: "Diners料率" },
];

export type AdyenStoreMerchantCategoryChangeRequestData =
  AdyenStoreMerchantCategoryChangeRequestCsvData & {
    shopName: string | null;
    hasNonDefaultFeeRate: boolean;
  };

export const useAdyenStoreMerchantCategoryChangeRequestForm = ({
  shops,
  setAdyenStoreMerchantCategoryChangeRequestCsvShopIds,
}: {
  shops: ShopForAdyenStoreMerchantCategoryChangeRequestCsv[];
  setAdyenStoreMerchantCategoryChangeRequestCsvShopIds: Dispatch<SetStateAction<string[]>>;
}) => {
  const [dinii, getContext] = useDinii();
  const [
    adyenStoreMerchantCategoryChangeRequestFile,
    setAdyenStoreMerchantCategoryChangeRequestFile,
  ] = useState<UploadFile<any> | null>(null);
  const [scheduledDate, setScheduledDate] = useState<Dayjs | null>(null);
  const [step, setStep] = useState<"form" | "verify" | "success">("form");
  const [
    adyenStoreMerchantCategoryChangeRequestCsvData,
    setAdyenStoreMerchantCategoryChangeRequestCsvData,
  ] = useState<AdyenStoreMerchantCategoryChangeRequestCsvData[]>([]);

  const [{ loading: loadingVerifyCsv }, verifyCsv] = useAsyncFn(
    async (uploadedFile: UploadFile<any>) => {
      const file = uploadedFile.originFileObj;
      if (!file) {
        return { error: "ファイルが選択されていません" };
      }

      const context = await getContext();
      if (!context) {
        return { error: "不明なエラーが発生しました" };
      }

      try {
        const formData = new FormData();
        formData.append("adyenStoreMerchantCategoryChangeRequest", file);

        const { data } = await dinii.adyenStoreMerchantCategoryChangeRequest.verify(
          context,
          formData,
        );

        return { data: data.data };
      } catch (error) {
        if (error instanceof AxiosError) {
          return { error: error.response?.data.message };
        }
        return { error: "不明なエラーが発生しました" };
      }
    },
    [getContext, dinii],
  );

  const onSelectFiles = useCallback(
    async (file: UploadFile<any>) => {
      const result = await verifyCsv(file);
      if (result.error) {
        file.status = "error";
        file.error = result.error;
        setAdyenStoreMerchantCategoryChangeRequestFile(file);
        return;
      }
      if (result.data && result.data.length > 0) {
        setAdyenStoreMerchantCategoryChangeRequestCsvData(result.data);
        setAdyenStoreMerchantCategoryChangeRequestCsvShopIds(
          result.data.map((data) => data.shopId),
        );
      }
      setAdyenStoreMerchantCategoryChangeRequestFile(file);
    },
    [verifyCsv, setAdyenStoreMerchantCategoryChangeRequestCsvShopIds],
  );

  const removeUploadedFile = useCallback(() => {
    setAdyenStoreMerchantCategoryChangeRequestFile(null);
  }, []);

  const onSelectScheduledDate = useCallback((date: Dayjs | null) => {
    setScheduledDate(date);
  }, []);

  const isValidAdyenStoreMerchantCategoryChangeRequest = useMemo(
    () =>
      Boolean(
        adyenStoreMerchantCategoryChangeRequestFile &&
          adyenStoreMerchantCategoryChangeRequestFile.status !== "error" &&
          scheduledDate,
      ),
    [adyenStoreMerchantCategoryChangeRequestFile, scheduledDate],
  );

  const onVerifyForm = useCallback(() => {
    setStep("verify");
  }, []);

  const [{ loading: loadingSubmitForm }, onSubmitForm] = useAsyncFn(async () => {
    const file = adyenStoreMerchantCategoryChangeRequestFile?.originFileObj;
    const context = await getContext();
    if (!file || !context || !scheduledDate) return;

    try {
      const formData = new FormData();
      formData.append("adyenStoreMerchantCategoryChangeRequest", file);
      formData.append("scheduledDate", scheduledDate.format("YYYY-MM-DD"));

      await dinii.adyenStoreMerchantCategoryChangeRequest.create(context, formData);
      setStep("success");
    } catch (error) {
      if (error instanceof AxiosError) {
        return message.error(error.response?.data.message);
      }
      return message.error("不明なエラーが発生しました");
    }
  }, [adyenStoreMerchantCategoryChangeRequestFile, scheduledDate, getContext, dinii]);

  const adyenStoreMerchantCategoryChangeRequestData: AdyenStoreMerchantCategoryChangeRequestData[] =
    useMemo(() => {
      const shopIdToShopMap = keyBy(shops, "shopId");

      const existNonDefaultFeeRate = (data: AdyenStoreMerchantCategoryChangeRequestCsvData) =>
        adyenPaymentMethodsWithCsvColumn.some(({ adyenPaymentMethod, column }) => {
          const value = data[column];

          const defaultFeeRate = isSmallAndMediumEnterpriseStoreMerchantCategory({
            merchantCategory: data.MCC,
          })
            ? DEFAULT_ADYEN_SMALL_AND_MEDIUM_ENTERPRISE_TERMINAL_PAYMENT_METHOD_FEE_RATE[
                adyenPaymentMethod
              ]
            : DEFAULT_ADYEN_TERMINAL_PAYMENT_METHOD_FEE_RATE[adyenPaymentMethod];

          // NOTE: Amex との契約が終わっていないため、デフォルト料率は暫定のもの
          // そのため、Amex の料率が null の場合は非デフォルト料率としない
          if (adyenPaymentMethod === AdyenPaymentMethodTypeEnum.AmexCard && value === null) {
            return false;
          }

          return value !== defaultFeeRate;
        });

      return adyenStoreMerchantCategoryChangeRequestCsvData
        .map((data) => ({
          ...data,
          // NOTE: shop がない場合エラーにしたいため、判定のために shopName を null としている
          shopName: shopIdToShopMap[data.shopId]?.name ?? null,
          hasNonDefaultFeeRate: existNonDefaultFeeRate(data),
        }))
        .sort((a, b) => {
          // NOTE: 店舗が存在しないものを先頭に表示する
          if (a.shopName === null) return -1;
          if (b.shopName === null) return 1;

          // NOTE: 非デフォルト料率のものを先に表示する
          if (a.hasNonDefaultFeeRate) return -1;
          if (b.hasNonDefaultFeeRate) return 1;

          return 0;
        });
    }, [adyenStoreMerchantCategoryChangeRequestCsvData, shops]);

  return {
    step,
    adyenStoreMerchantCategoryChangeRequestFormValues: {
      adyenStoreMerchantCategoryChangeRequestFile,
      scheduledDate,
    },
    isValidAdyenStoreMerchantCategoryChangeRequest,
    adyenStoreMerchantCategoryChangeRequestData,
    onSelectFiles,
    removeUploadedFile,
    onSelectScheduledDate,
    onVerifyForm,
    onSubmitForm,
    loadingVerifyCsv,
    loadingSubmitForm,
  };
};
