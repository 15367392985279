import React, { useCallback, useMemo } from "react";
import useAsyncFn from "react-use/esm/useAsyncFn";
import styled from "styled-components";
import { Alert, Button } from "antd";
import { DownloadOutlined, WarningOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { saveAs } from "file-saver";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import { useDinii } from "hooks/useDinii";
import { useFilterConditions } from "hooks/useFilterConditions";
import { useShop } from "hooks/useShop";
import { dayjsToDateString } from "libs/DateString";

import {
  AdyenTerminalPaymentHistoryFilter,
  FilterConditions,
} from "./AdyenTerminalPaymentHistoryFilter";
import { AdyenTerminalPaymentHistoryTable } from "./AdyenTerminalPaymentHistoryTable";
import {
  AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery,
  useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery,
  useAdyenTerminalPaymentHistoryGetGmoBankAccountShopQuery,
  useAdyenTerminalPaymentHistoryGetPayoutJobSchedulesQuery,
} from "./queries";

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const MAX_MONTHS_RANGE = 3;

const getInitialTimeRange = () => {
  const now = dayjs();
  return {
    from: dayjsToDateString(now.subtract(1, "day")),
    to: dayjsToDateString(now),
  };
};

const WarnForDelayedDepositWrapper = styled.div`
  display: flex;
  justify-content: end;
  color: ${colors.Text.Secondary};
`;

export type PaymentHistoryType = NonNullable<
  AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery["adyenPaymentReportTerminalPaymentAuthorizedEvent"]
>[number];

// NOTE: 振込はキャプチャの時刻に基づき行われるが、決済してからキャプチャされるまで1時間のラグがある
// そのため、1時間にも少しブレがあることを考慮し、締め日 22:50 以降の決済は次のサイクルになる可能性があることを警告する
const getApproximateCutOffTime = (time: dayjs.Dayjs) =>
  time.startOf("day").set("hour", 22).set("minute", 50);

export const AdyenTerminalPaymentHistory = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const [dinii, getContext] = useDinii();

  const { filterConditions, updateFilterCondition } = useFilterConditions<FilterConditions>({
    ...getInitialTimeRange(),
  });

  const {
    data: authorizedEventData,
    loading: loadingAuthorizedEvent,
    error: getAuthorizedEventError,
  } = useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery(
    shopId && filterConditions.from && filterConditions.to
      ? {
          variables: {
            shopId,
            since: dayjs(filterConditions.from).startOf("day").toISOString(),
            until: dayjs(filterConditions.to).endOf("day").toISOString(),
          },
        }
      : { skip: true },
  );

  const {
    data: payoutJobScheduleData,
    loading: loadingPayoutJobSchedule,
    error: getPayoutJobScheduleError,
  } = useAdyenTerminalPaymentHistoryGetPayoutJobSchedulesQuery(
    filterConditions.from && filterConditions.to
      ? {
          variables: {
            sinceDate: dayjsToDateString(dayjs(filterConditions.from)),
            untilDate: dayjsToDateString(dayjs(filterConditions.to).add(1, "day")),
          },
        }
      : { skip: true },
  );

  const {
    data: gmoBankAccountData,
    loading: loadingGmoBankAccount,
    error: getGmoBankAccountError,
  } = useAdyenTerminalPaymentHistoryGetGmoBankAccountShopQuery(
    shopId
      ? {
          variables: {
            shopId,
          },
        }
      : { skip: true },
  );

  const payoutJobSchedules = useMemo(() => {
    const gmoBankAccountShop = gmoBankAccountData?.gmoBankAccountShop[0];
    if (!payoutJobScheduleData || !gmoBankAccountShop) {
      return [];
    }
    // NOTE: サイクルに紐づく payoutJobSchedule を filter する
    // クエリの時点で filter することもできるが、そのためには gmoBankAccountShop 取得後に payoutJobSchedule をクエリする必要がある
    // 一方フロントで filter するならば gmoBankAccountShop と payoutJobSchedule は並列で取得できるため、こちらのほうが効率が良いと判断した
    return (
      payoutJobScheduleData?.payoutJobSchedule.filter(
        (schedule) => schedule.depositCycle === gmoBankAccountShop.depositCycleType,
      ) ?? []
    );
  }, [gmoBankAccountData, payoutJobScheduleData]);

  const paymentHistories: PaymentHistoryType[] = useMemo(
    () => authorizedEventData?.adyenPaymentReportTerminalPaymentAuthorizedEvent ?? [],
    [authorizedEventData?.adyenPaymentReportTerminalPaymentAuthorizedEvent],
  );

  const isTransactionCanBeDelayedInDeposit = useCallback(
    ({ createdAt }: Pick<PaymentHistoryType, "createdAt">) => {
      const createdAtDayJs = dayjs(createdAt);

      return payoutJobSchedules.some(({ closingDate }) => {
        const closingDayJs = dayjs(closingDate);
        const start = getApproximateCutOffTime(closingDayJs.startOf("day"));
        const end = closingDayJs.add(1, "day").startOf("day");
        return createdAtDayJs.isSameOrAfter(start) && createdAtDayJs.isBefore(end);
      });
    },
    [payoutJobSchedules],
  );

  const shouldShowDelayedDepositWarning = useMemo(
    () => paymentHistories.some((payment) => isTransactionCanBeDelayedInDeposit(payment)),
    [isTransactionCanBeDelayedInDeposit, paymentHistories],
  );

  const [{ loading: isDownloading }, submit] = useAsyncFn(async () => {
    const context = await getContext();

    if (!shopId || !filterConditions.from || !filterConditions.to || !context) {
      return;
    }

    const fromDate = dayjs(filterConditions.from);
    const toDate = dayjs(filterConditions.to);
    const maxToDate = fromDate.add(MAX_MONTHS_RANGE, "months");

    if (toDate.isSameOrAfter(maxToDate)) {
      message.error(`日付の範囲は${MAX_MONTHS_RANGE}ヶ月以内でなければなりません`);
      return;
    }

    const from = dayjs(filterConditions.from).tz("Asia/Tokyo").format("YYYYMMDD");
    const to = dayjs(filterConditions.to).tz("Asia/Tokyo").format("YYYYMMDD");

    try {
      const { data } = await dinii.aggregatedData.adyenTerminalPayment.download(context, {
        shopId,
        from,
        to,
      });
      saveAs(data, `${shopId}_${from}_${to}_terminal_payment.zip`);

      message.success("ダウンロードが完了しました");
    } catch (err) {
      message.error("不明なエラーが発生しました");
    }
  }, [getContext, shopId, filterConditions, dinii.aggregatedData.adyenTerminalPayment]);

  const disabled = paymentHistories.length === 0;

  const loading = loadingAuthorizedEvent || loadingPayoutJobSchedule || loadingGmoBankAccount;
  const error = getAuthorizedEventError || getPayoutJobScheduleError || getGmoBankAccountError;

  return (
    <DashboardLayout title="端末決済履歴一覧">
      <PageHeader
        title="端末決済履歴一覧"
        footer={
          <HeaderContainer>
            <SelectorContainer>
              <AdyenTerminalPaymentHistoryFilter
                filterConditions={filterConditions}
                updateFilterCondition={updateFilterCondition}
              />
            </SelectorContainer>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              disabled={disabled}
              loading={isDownloading}
              onClick={submit}
            >
              ダウンロード
            </Button>
          </HeaderContainer>
        }
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {shouldShowDelayedDepositWarning ? (
        <>
          <WarnForDelayedDepositWrapper>
            <WarningOutlined />
            <Spacer size={4} />
            こちらのアイコンのついた取引は、入金が次のサイクルになる可能性があります。
          </WarnForDelayedDepositWrapper>
          <Spacer size={16} />
        </>
      ) : (
        <></>
      )}
      <AdyenTerminalPaymentHistoryTable
        loading={loading}
        paymentHistories={paymentHistories}
        getShouldShowWarning={isTransactionCanBeDelayedInDeposit}
      />
    </DashboardLayout>
  );
};
