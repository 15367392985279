import React, { useCallback, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Form,
  Input,
  Popconfirm,
  Select,
  Table as AntdTable,
  Typography,
} from "antd";
import { isEqual, omit } from "lodash";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { FormActions } from "components/Form/FormActions";
import { InputCode } from "components/Input/InputCode";
import { Table } from "components/Table";
import { colors } from "constants/colors";
import { usePagination } from "hooks/usePagination";

import { ConfirmationModal } from "../ConfirmationModal";
import { Choice, FoodingJournalDepartmentMaster, FoodingJournalGroupMaster, Menu } from "../types";

const { Paragraph } = Typography;
const { Column } = AntdTable;

export type FoodingJournalMenuSource = {
  foodingJournalMenuId: string | undefined;
  foodingJournalName: string | undefined;
  code: string | undefined;
  foodingJournalDepartmentId: string | undefined;
  foodingJournalGroupId: string | undefined;
};

export type NonNullableFoodingJournalMenuSource = {
  foodingJournalMenuId: string | undefined;
  code: string;
  foodingJournalName: string;
  foodingJournalDepartmentId: string;
  foodingJournalGroupId: string;
};

// NOTE: Form で管理しているデータ構造
type FoodingJournalMenuForm = Record<
  string,
  Omit<FoodingJournalMenuSource, "foodingJournalMenuId">
>;

export type MenuWithFoodingJournalMenu = Pick<
  Menu,
  "category" | "menuId" | "name" | "menuOptions"
> &
  FoodingJournalMenuSource & {
    choices: (Pick<Choice, "choiceId" | "category" | "name"> &
      FoodingJournalMenuSource & {
        menuId: string;
        optionId: string;
      })[];
  };

type RowItem =
  | ({ type: "menu" } & MenuWithFoodingJournalMenu)
  | ({ type: "choice" } & Pick<MenuWithFoodingJournalMenu, "choices">["choices"][number]);

type Props = {
  loading: boolean;
  menus: MenuWithFoodingJournalMenu[];
  foodingJournalDepartmentMasters: FoodingJournalDepartmentMaster[];
  foodingJournalGroupMasters: FoodingJournalGroupMaster[];
  menusAndPlans: Pick<
    FoodingJournalMenuSource,
    "foodingJournalMenuId" | "foodingJournalName" | "code"
  >[];
  upsertCodes: (args: {
    menuCodes: (NonNullableFoodingJournalMenuSource & { menuId: string })[];
    choiceCodes: (NonNullableFoodingJournalMenuSource & { choiceId: string })[];
  }) => Promise<void>;
  refetchMenus: () => Promise<void>;
  deleteFoodingJournalMenu: ({
    foodingJournalMenuId,
  }: {
    foodingJournalMenuId: string;
  }) => Promise<void>;
};

const isEditingValueEmpty = ({
  code,
  foodingJournalName,
  foodingJournalDepartmentId,
  foodingJournalGroupId,
}: {
  code: string | undefined;
  foodingJournalName: string | undefined;
  foodingJournalDepartmentId: string | undefined;
  foodingJournalGroupId: string | undefined;
}) => !code && !foodingJournalName && !foodingJournalDepartmentId && !foodingJournalGroupId;

const isCodesValid = <T extends FoodingJournalMenuSource>(
  rows: T[],
): rows is (T & NonNullableFoodingJournalMenuSource)[] =>
  rows.every((row) =>
    Boolean(
      row.code &&
        row.foodingJournalName &&
        row.foodingJournalDepartmentId &&
        row.foodingJournalGroupId,
    ),
  );

// NOTE: Form で管理するデータのキーを生成する関数
const genDataKey = (rowItem: RowItem) => {
  switch (rowItem.type) {
    case "menu":
      return rowItem.menuId;
    case "choice":
      return rowItem.choiceId;
  }
};

// NOTE: Table の一行ごとのキーを生成する関数
const genRowKey = (rowItem: RowItem) => {
  switch (rowItem.type) {
    case "menu":
      return `${rowItem.category.categoryId}-${rowItem.menuId}`;
    case "choice":
      return `${rowItem.category.categoryId}-${rowItem.menuId}-${rowItem.choiceId}`;
  }
};

export const MenuTable = React.memo<Props>(
  ({
    loading,
    menus,
    foodingJournalDepartmentMasters,
    foodingJournalGroupMasters,
    menusAndPlans,
    upsertCodes,
    refetchMenus,
    deleteFoodingJournalMenu,
  }) => {
    const [form] = Form.useForm();
    const [pagination, setPagination] = usePagination();

    const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false);

    const [editingFoodingJournalMenus, setEditingFoodingJournalMenus] = useState<
      Record<string, FoodingJournalMenuSource & { type: "menu" | "choice" }>
    >({});

    const rows = useMemo<RowItem[]>(
      () =>
        menus.map((menu) => ({
          ...menu,
          type: "menu",
          children:
            menu.choices.length > 0
              ? menu.choices.map((choice) => ({ ...choice, type: "choice" }))
              : null,
        })),
      [menus],
    );

    const expandableConfig = useMemo(() => {
      const defaultExpandedRowKeys = rows.flatMap((row) =>
        row.type === "menu" && row.menuOptions.length > 0 ? [genRowKey(row)] : [],
      );
      return {
        defaultExpandedRowKeys,
      };
    }, [rows]);

    const openModal = useCallback(() => setShouldShowConfirmationModal(true), []);

    const closeModal = useCallback(() => setShouldShowConfirmationModal(false), []);

    const handleChange = useCallback(
      ({ rowItem }: { rowItem: RowItem }) => {
        const dataKey = genDataKey(rowItem);

        const editingValue = (form.getFieldsValue() as FoodingJournalMenuForm)[dataKey];

        if (!editingValue) return;

        const mergedValue = {
          type: rowItem.type,
          foodingJournalMenuId: rowItem.foodingJournalMenuId,
          foodingJournalName: editingValue.foodingJournalName ?? rowItem.foodingJournalName,
          code: editingValue.code ?? rowItem.code,
          foodingJournalDepartmentId:
            editingValue.foodingJournalDepartmentId ?? rowItem.foodingJournalDepartmentId,
          foodingJournalGroupId:
            editingValue.foodingJournalGroupId ?? rowItem.foodingJournalGroupId,
        };

        if (
          isEditingValueEmpty(editingValue) ||
          isEqual(mergedValue, {
            type: rowItem.type,
            foodingJournalMenuId: rowItem.foodingJournalMenuId,
            foodingJournalName: rowItem.foodingJournalName,
            code: rowItem.code,
            foodingJournalDepartmentId: rowItem.foodingJournalDepartmentId,
            foodingJournalGroupId: rowItem.foodingJournalGroupId,
          })
        ) {
          return setEditingFoodingJournalMenus((m) => omit(m, dataKey));
        }

        setEditingFoodingJournalMenus((m) => ({ ...m, [dataKey]: mergedValue }));

        form.setFieldValue(dataKey, mergedValue);
      },
      [form],
    );

    const handleConfirm = useCallback(async () => {
      try {
        await form.validateFields(
          Object.entries(editingFoodingJournalMenus).map(([id]) => [id]),
          { recursive: true },
        );
      } catch {
        return;
      }

      openModal();
    }, [editingFoodingJournalMenus, form, openModal]);

    const handleClear = useCallback(() => {
      setEditingFoodingJournalMenus({});

      form.resetFields();
    }, [form]);

    const handleSubmit = useCallback(async () => {
      const menuCodes = Object.entries(editingFoodingJournalMenus)
        .filter(([_, menu]) => menu.type === "menu")
        .map(([id, menu]) => ({
          menuId: id,
          name: menu.foodingJournalName,
          ...menu,
        }));

      const choiceCodes = Object.entries(editingFoodingJournalMenus)
        .filter(([_, menu]) => menu.type === "choice")
        .map(([id, choice]) => ({
          choiceId: id,
          name: choice.foodingJournalName,
          ...choice,
        }));

      if (!isCodesValid(menuCodes) || !isCodesValid(choiceCodes)) return;

      await upsertCodes({ menuCodes, choiceCodes });

      await refetchMenus();

      form.resetFields();

      setEditingFoodingJournalMenus({});

      closeModal();
    }, [closeModal, editingFoodingJournalMenus, form, refetchMenus, upsertCodes]);

    const handleDelete = useCallback(
      async ({ rowItem }: { rowItem: RowItem }) => {
        const dataKey = genDataKey(rowItem);

        const { foodingJournalMenuId } = rowItem;

        if (foodingJournalMenuId) {
          await deleteFoodingJournalMenu({ foodingJournalMenuId });
          await refetchMenus();
        }

        setEditingFoodingJournalMenus((m) => omit(m, dataKey));

        form.resetFields([
          [dataKey, "code"],
          [dataKey, "foodingJournalName"],
          [dataKey, "foodingJournalDepartmentId"],
          [dataKey, "foodingJournalGroupId"],
        ]);
      },
      [deleteFoodingJournalMenu, form, refetchMenus],
    );

    return (
      <>
        <Form form={form} component={false}>
          <Table
            rowKey={genRowKey}
            dataSource={rows}
            loading={loading}
            bordered
            pagination={pagination}
            onChange={({ position: _, ...pagination }) => setPagination(pagination)}
            expandable={expandableConfig}
            footer={() => (
              <>
                <FormActions>
                  <Button onClick={handleClear}>クリア</Button>

                  <Badge count={Object.keys(editingFoodingJournalMenus).length}>
                    <Button
                      type="primary"
                      disabled={Object.keys(editingFoodingJournalMenus).length === 0}
                      onClick={handleConfirm}
                    >
                      確認
                    </Button>
                  </Badge>
                </FormActions>
              </>
            )}
          >
            <Column
              title="カテゴリ名"
              width={150}
              fixed="left"
              render={(_: string, rowItem: RowItem) => {
                if (rowItem.type !== "menu") {
                  return {
                    props: {
                      style: {
                        backgroundColor: colors.BackGround.Secondary,
                      },
                    },
                  };
                }

                const { category } = rowItem;
                return {
                  children: category.name,
                };
              }}
            />

            <Column dataIndex="name" title="商品名" width={200} />

            <Column
              dataIndex="code"
              title="メニューコード"
              align="left"
              render={(_: string, rowItem: RowItem) => (
                <FormItem
                  style={{ width: 300 }}
                  key={genRowKey(rowItem)}
                  name={[genDataKey(rowItem), "code"]}
                  rules={[
                    {
                      required: true,
                      pattern: /^\d{1,18}$/,
                      message: `18桁以内の数字で入力してください`,
                    },
                    {
                      validator: async (_, value) => {
                        if (!value) return;

                        const editingRows = Object.entries(editingFoodingJournalMenus).map(
                          ([key, row]) => ({
                            key,
                            ...row,
                          }),
                        );
                        const conflictingExistingRow = menusAndPlans.find(
                          ({ code, foodingJournalMenuId }) =>
                            code === value && foodingJournalMenuId !== rowItem.foodingJournalMenuId,
                        );

                        if (conflictingExistingRow) {
                          const editingRow = editingRows.find(
                            ({ code, foodingJournalMenuId }) =>
                              code !== value &&
                              foodingJournalMenuId === conflictingExistingRow.foodingJournalMenuId,
                          );

                          if (!editingRow) {
                            throw new Error(
                              `既存のコードと競合しています (${conflictingExistingRow.foodingJournalName})`,
                            );
                          }
                        }

                        const conflictingEditingRows = editingRows.filter(
                          ({ code }) => code === value,
                        );

                        if (
                          conflictingEditingRows[0] &&
                          conflictingEditingRows[0].key !== genDataKey(rowItem)
                        ) {
                          throw new Error(
                            `既存のコードと競合しています (${conflictingEditingRows[0].foodingJournalName})`,
                          );
                        }
                      },
                    },
                  ]}
                >
                  <InputCode
                    defaultValue={rowItem.code}
                    placeholder="メニューコード"
                    onBlur={() => handleChange({ rowItem })}
                  />
                </FormItem>
              )}
            />

            <Column
              dataIndex="foodingJournalName"
              title="メニュー名称"
              align="left"
              width="300"
              render={(_: string, rowItem: RowItem) => (
                <FormItem
                  style={{ width: 300 }}
                  key={genRowKey(rowItem)}
                  name={[genDataKey(rowItem), "foodingJournalName"]}
                  rules={[
                    {
                      required: true,
                      max: 20,
                      message: `20文字以下の名称を入力してください`,
                    },
                  ]}
                >
                  <Input
                    defaultValue={rowItem.foodingJournalName}
                    placeholder="メニュー名称"
                    onBlur={() => handleChange({ rowItem })}
                  />
                </FormItem>
              )}
            />

            <Column
              dataIndex="foodingJournalDepartmentId"
              title="部門"
              align="left"
              width="200"
              render={(_: string, rowItem: RowItem) => (
                <FormItem
                  style={{ width: 200 }}
                  key={genRowKey(rowItem)}
                  name={[genDataKey(rowItem), "foodingJournalDepartmentId"]}
                  rules={[
                    {
                      required: true,
                      message: "部門を選択してください",
                    },
                  ]}
                >
                  <Select<string>
                    showSearch
                    defaultValue={rowItem.foodingJournalDepartmentId}
                    placeholder="部門"
                    onSelect={() => handleChange({ rowItem })}
                    filterOption={(inputValue, option) =>
                      Boolean(option?.props?.children?.includes(inputValue))
                    }
                  >
                    {foodingJournalDepartmentMasters.map(({ id, code, name }) => (
                      <Select.Option key={id} value={id}>
                        {`${code}: ${name}`}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              )}
            />

            <Column
              dataIndex="foodingJournalGroupId"
              title="分類"
              align="left"
              width="150"
              render={(_: string, rowItem: RowItem) => (
                <FormItem
                  style={{ width: 150 }}
                  key={genRowKey(rowItem)}
                  name={[genDataKey(rowItem), "foodingJournalGroupId"]}
                  rules={[
                    {
                      required: true,
                      message: "分類を選択してください",
                    },
                  ]}
                >
                  <Select<string>
                    showSearch
                    defaultValue={rowItem.foodingJournalGroupId}
                    placeholder="分類"
                    onSelect={() => handleChange({ rowItem })}
                    filterOption={(inputValue, option) =>
                      Boolean(option?.props?.children?.includes(inputValue))
                    }
                  >
                    {foodingJournalGroupMasters.map(({ id, code, name }) => (
                      <Select.Option key={id} value={id}>
                        {`${code}: ${name}`}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              )}
            />

            <Column
              title=""
              align="left"
              width="50"
              render={(_: string, rowItem: RowItem) => (
                <>
                  {rowItem.foodingJournalMenuId ? (
                    <Popconfirm
                      title={
                        <>
                          <Paragraph>設定を削除しますか？</Paragraph>
                          <Paragraph>一度削除した設定を元に戻すことはできません。</Paragraph>
                        </>
                      }
                      okText="はい"
                      cancelText="キャンセル"
                      onConfirm={() => {
                        handleDelete({ rowItem });
                      }}
                    >
                      <DeleteIcon />
                    </Popconfirm>
                  ) : (
                    <DeleteIcon onClick={() => handleDelete({ rowItem })} />
                  )}
                </>
              )}
            />
          </Table>
        </Form>

        {shouldShowConfirmationModal && (
          <ConfirmationModal
            editingFoodingJournalMenus={editingFoodingJournalMenus}
            menus={menus}
            foodingJournalDepartmentMasters={foodingJournalDepartmentMasters}
            foodingJournalGroupMasters={foodingJournalGroupMasters}
            onSubmit={handleSubmit}
            onCancel={closeModal}
            loading={loading}
          />
        )}
      </>
    );
  },
);
