import React, { memo } from "react";
import { Checkbox } from "antd";

import { FormItemProps, withFormDependencies } from "components/antd/Form";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ShouldPrintSalesDetailSectionFields = memo<Props>((props) => (
  <EditCashRegisterConfigFormItem.NonProperty
    shouldUpdate={withFormDependencies(({ shouldPrintSalesDetail }) => [shouldPrintSalesDetail])}
    {...props}
  >
    {({ getFieldValue }) => {
      const shouldPrintSalesDetail = getFieldValue("shouldPrintSalesDetail");
      return (
        <>
          <EditCashRegisterConfigFormItem
            valuePropName="checked"
            name="shouldPrintSalesByBusinessOperationHour"
            endSpacer={null}
          >
            <Checkbox disabled={!shouldPrintSalesDetail}>時間帯別</Checkbox>
          </EditCashRegisterConfigFormItem>

          <EditCashRegisterConfigFormItem
            valuePropName="checked"
            name="shouldPrintSalesByDiningOption"
            endSpacer={null}
          >
            <Checkbox disabled={!shouldPrintSalesDetail}>店内・店外</Checkbox>
          </EditCashRegisterConfigFormItem>

          <EditCashRegisterConfigFormItem
            valuePropName="checked"
            name="shouldPrintTableAreaSaleses"
            endSpacer={null}
          >
            <Checkbox disabled={!shouldPrintSalesDetail}>エリア別</Checkbox>
          </EditCashRegisterConfigFormItem>

          <EditCashRegisterConfigFormItem
            valuePropName="checked"
            name="shouldPrintSalesByMenuType"
            endSpacer={null}
          >
            <Checkbox disabled={!shouldPrintSalesDetail}>メニュータイプ別</Checkbox>
          </EditCashRegisterConfigFormItem>

          <EditCashRegisterConfigFormItem
            valuePropName="checked"
            name="shouldPrintInflowSourceTagSaleses"
            endSpacer={null}
          >
            <Checkbox disabled={!shouldPrintSalesDetail}>媒体別</Checkbox>
          </EditCashRegisterConfigFormItem>
        </>
      );
    }}
  </EditCashRegisterConfigFormItem.NonProperty>
));
