import { AnalyticsSetting, CurrentSales } from "types/graphql";

export const convertCurrentSalesWithAnalyticsSetting = <
  T extends Pick<
    CurrentSales,
    | "activeTaxExcludedAmount"
    | "activeTaxIncludedAmount"
    | "activeUnitTaxIncludedAmount"
    | "activeUnitTaxExcludedAmount"
    | "currentTaxExcludedAmount"
    | "currentTaxIncludedAmount"
    | "currentUnitTaxExcludedAmount"
    | "currentUnitTaxIncludedAmount"
    | "taxExcludedSalesBudgetAmount"
    | "taxIncludedSalesBudgetAmount"
  >,
>({
  currentSales,
  analyticsSetting,
}: {
  currentSales: T;
  analyticsSetting: Pick<AnalyticsSetting, "displayTaxIncluded">;
}): Omit<
  T,
  | "activeTaxExcludedAmount"
  | "activeTaxIncludedAmount"
  | "activeUnitTaxIncludedAmount"
  | "activeUnitTaxExcludedAmount"
  | "currentTaxExcludedAmount"
  | "currentTaxIncludedAmount"
  | "currentUnitTaxExcludedAmount"
  | "currentUnitTaxIncludedAmount"
  | "taxExcludedSalesBudgetAmount"
  | "taxIncludedSalesBudgetAmount"
> & {
  currentUnitSales: number;
  currentSales: number;
  activeUnitSales: number;
  activeSales: number;
  salesBudgetAmount: number | null;
} => {
  const {
    activeTaxExcludedAmount,
    activeTaxIncludedAmount,
    activeUnitTaxExcludedAmount,
    activeUnitTaxIncludedAmount,
    currentTaxExcludedAmount,
    currentTaxIncludedAmount,
    currentUnitTaxExcludedAmount,
    currentUnitTaxIncludedAmount,
    taxExcludedSalesBudgetAmount,
    taxIncludedSalesBudgetAmount,
    ...rest
  } = currentSales;

  return {
    ...rest,
    activeSales: analyticsSetting.displayTaxIncluded
      ? activeTaxIncludedAmount
      : activeTaxExcludedAmount,
    activeUnitSales: analyticsSetting.displayTaxIncluded
      ? activeUnitTaxIncludedAmount
      : activeUnitTaxExcludedAmount,
    currentSales: analyticsSetting.displayTaxIncluded
      ? currentTaxIncludedAmount
      : currentTaxExcludedAmount,
    currentUnitSales: analyticsSetting.displayTaxIncluded
      ? currentUnitTaxIncludedAmount
      : currentUnitTaxExcludedAmount,
    salesBudgetAmount:
      (analyticsSetting.displayTaxIncluded
        ? taxIncludedSalesBudgetAmount
        : taxExcludedSalesBudgetAmount) ?? null,
  };
};
