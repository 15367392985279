import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Alert, Button, Tag, TreeSelect } from "antd";
import dayjs from "dayjs";
import { difference } from "lodash";

import { Spacer } from "components/Spacer";
import { useAnalyticsSetting } from "hooks/useAnalyticsSetting";
import { useCorporation } from "hooks/useCorporation";

import { convertCurrentSalesWithAnalyticsSetting } from "../util";

import { CurrentSalesTable } from "./CurrentSalesTable";
import { useCurrentSalesGetShopQuery, useDashboardCurrentSalesListQuery } from "./queries";
import { CurrentSalesWithAnalyticsSetting } from "./types";

export const currentSalesDefaultPageSize = 10;

const ShopSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTreeSelect = styled(TreeSelect<string[]>)`
  width: 100%;
`;

/**
 * 売上速報データは RDB からリアルタイムにクエリ・計算したものを利用するため、クエリの回数はなるべく最小限に抑えるように実装している。
 * 最大でも 10 店舗分のデータしか一度にクエリしないように制御し、また店舗選択時にはドロップダウンが閉じたときにのみデータを再取得する。
 */
export const CurrentSalesList = memo(() => {
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId ?? null;
  const { analyticsSetting, loading: loadingAnalyticsSetting } = useAnalyticsSetting({
    corporationId: corporationId ?? null,
  });

  const [targetShopIds, setTargetShopIds] = useState<string[]>([]);
  const [selectedShopIds, setSelectedShopIds] = useState<string[] | null>(null);
  const [lastCurrentSalesesFetched, setLastCurrentSalesesFetched] = useState(dayjs());

  const {
    data: getShopsData,
    error: getShopsError,
    loading: loadingGetShops,
  } = useCurrentSalesGetShopQuery(
    corporationId ? { variables: { corporationId } } : { skip: true },
  );
  const companies = useMemo(
    () => getShopsData?.corporation[0]?.companies ?? [],
    [getShopsData?.corporation],
  );
  const allShopIds = useMemo(
    () => companies.flatMap(({ shops }) => shops.map(({ shopId }) => shopId), [companies]),
    [companies],
  );

  useEffect(() => {
    setTargetShopIds(allShopIds.slice(0, currentSalesDefaultPageSize));
  }, [allShopIds]);

  const {
    data: getCurrentSalesData,
    loading: loadingGetCurrentSaleses,
    error: getCurrentSalesError,
  } = useDashboardCurrentSalesListQuery(
    targetShopIds.length > 0
      ? {
          variables: { input: { shopIds: targetShopIds } },
          onCompleted: () => setLastCurrentSalesesFetched(dayjs()),
        }
      : { skip: true },
  );

  const shopTreeData = useMemo(
    () =>
      (
        companies
          .filter(({ shops }) => shops.length > 0)
          .sort((a, b) => a.name.localeCompare(b.name)) ?? []
      ).map((company) => ({
        key: company.id,
        value: company.id,
        title: company.name,
        selectable: true,
        children: company.shops
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((shop) => ({
            key: shop.shopId,
            value: shop.shopId,
            title: shop.name,
            selectable: true,
          })),
      })),
    [companies],
  );

  const currentSaleses: CurrentSalesWithAnalyticsSetting[] = useMemo(
    () =>
      getCurrentSalesData?.dashboardCurrentSaleses.currentSaleses
        .sort((a, b) => a.shopName.localeCompare(b.shopName))
        .map((currentSales) =>
          convertCurrentSalesWithAnalyticsSetting({ currentSales, analyticsSetting }),
        ) ?? [],
    [analyticsSetting, getCurrentSalesData?.dashboardCurrentSaleses.currentSaleses],
  );
  const totalShopIdsCount = useMemo(
    () => (selectedShopIds ? selectedShopIds.length : allShopIds.length),
    [allShopIds.length, selectedShopIds],
  );

  const handleShopsSelect = useCallback((shopIds: string[]) => {
    setLastCurrentSalesesFetched(dayjs());

    if (shopIds.length === 0) {
      return setSelectedShopIds(null);
    }

    setSelectedShopIds(shopIds);
  }, []);

  const handleUpdateTable = useCallback(() => {
    if (!selectedShopIds) return;

    setTargetShopIds(selectedShopIds.slice(0, currentSalesDefaultPageSize));
  }, [selectedShopIds]);

  const handlePageChange = useCallback(
    ({ page }: { page: number }) => {
      setLastCurrentSalesesFetched(dayjs());

      if (selectedShopIds) {
        return setTargetShopIds(
          selectedShopIds.slice(
            (page - 1) * currentSalesDefaultPageSize,
            page * currentSalesDefaultPageSize,
          ),
        );
      }

      setTargetShopIds(
        allShopIds.slice(
          (page - 1) * currentSalesDefaultPageSize,
          page * currentSalesDefaultPageSize,
        ),
      );
    },
    [allShopIds, selectedShopIds],
  );

  const handleShopSelectClear = useCallback(() => {
    setSelectedShopIds(null);
    setTargetShopIds(allShopIds.slice(0, currentSalesDefaultPageSize));
  }, [allShopIds]);

  const shouldShowUpdateTableButton = useMemo(
    () =>
      selectedShopIds !== null &&
      (selectedShopIds.length !== targetShopIds.length ||
        difference(selectedShopIds, targetShopIds).length > 0),
    [selectedShopIds, targetShopIds],
  );

  const loading = loadingGetShops || loadingGetCurrentSaleses;
  const error = getShopsError || getCurrentSalesError;

  return (
    <>
      <Spacer height={6} />

      <Tag color="processing">{lastCurrentSalesesFetched.format("YYYY/MM/DD HH:mm")} 時点</Tag>

      <Spacer height={16} />

      <ShopSelectContainer>
        <StyledTreeSelect
          treeData={shopTreeData}
          treeDefaultExpandAll
          treeCheckable
          value={selectedShopIds ?? []}
          defaultValue={allShopIds}
          placeholder="店舗"
          maxTagCount={5}
          allowClear
          onChange={handleShopsSelect}
          onClear={handleShopSelectClear}
          treeNodeFilterProp="title"
        />

        <Spacer width={12} />

        <Button type="primary" disabled={!shouldShowUpdateTableButton} onClick={handleUpdateTable}>
          更新
        </Button>
      </ShopSelectContainer>

      <Spacer height={24} />

      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <CurrentSalesTable
        currentSaleses={currentSaleses}
        totalShopCount={totalShopIdsCount}
        onChangePage={handlePageChange}
        loading={loading}
      />
    </>
  );
});
