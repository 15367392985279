import React, { memo, useCallback } from "react";
import { FormListFieldData } from "antd/lib";
import { ColumnsType } from "antd/lib/table";

import { FormList } from "components/antd/Form";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { EditWinboardPlanFormItem } from "pages/EditWinboardPlan/EditWinboardPlanForm/useEditWinboardPlanForm";

import { WinboardMenu } from "../../types";
import { WinboardCodeAndName } from "..";

import { WinboardGetAvailableCodeForOptionButtonField } from "./WinboardGetAvailableMenuCdForOptionButtonField";
import { PlanChoiceTable } from "./WinboardPlanChoiceTable";

type Props = {
  loading?: boolean;
  winboardMenus: Pick<WinboardMenu, "id" | "name" | "code">[];
  handleGetAvailableMenuCodeForOptionButtonPressed: () => void;
  winboardPlanCodeAndName: WinboardCodeAndName;
  setWinboardPlanChoicesCodeAndName: React.Dispatch<React.SetStateAction<WinboardCodeAndName[]>>;
};

type FormListFieldDataSource = {
  name: FormListFieldData["name"];
  key: FormListFieldData["key"];
};

export const PlanOptionTable = memo<Props>(
  ({
    loading,
    winboardMenus,
    handleGetAvailableMenuCodeForOptionButtonPressed,
    winboardPlanCodeAndName,
    setWinboardPlanChoicesCodeAndName,
  }) => {
    const [pagination, setPagination] = usePagination();

    const columns: ColumnsType<FormListFieldDataSource> = [
      {
        title: "オプション名",
        key: "name",
        render(_: unknown, field: FormListFieldDataSource) {
          return (
            <EditWinboardPlanFormItem.NonProperty noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const optionName = getFieldValue(["plan", "planOptions", field.name, "name"]);
                return <span>{optionName}</span>;
              }}
            </EditWinboardPlanFormItem.NonProperty>
          );
        },
      },
    ];

    const expandedRowRender = useCallback(
      (optionField: FormListFieldData) => (
        <PlanChoiceTable
          optionField={optionField}
          winboardMenus={winboardMenus}
          winboardPlanCodeAndName={winboardPlanCodeAndName}
          setWinboardPlanChoicesCodeAndName={setWinboardPlanChoicesCodeAndName}
        />
      ),
      [winboardMenus, winboardPlanCodeAndName, setWinboardPlanChoicesCodeAndName],
    );

    return (
      <FormList name={["plan", "planOptions"]}>
        {(planOptionFields) => (
          <Table
            rowKey="key"
            columns={columns}
            dataSource={planOptionFields}
            loading={loading}
            bordered
            expandable={{ expandedRowRender, defaultExpandAllRows: true }}
            pagination={pagination}
            onChange={({ position: _, ...pagination }) => setPagination(pagination)}
            shouldDisableMaxWidthScroll
            footer={() => (
              <WinboardGetAvailableCodeForOptionButtonField
                handleGetAvailableMenuCodeButtonPressed={
                  handleGetAvailableMenuCodeForOptionButtonPressed
                }
              />
            )}
          />
        )}
      </FormList>
    );
  },
);
