import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const WinboardMenuOptionsGetMenu = gql`
    query WinboardMenuOptionsGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    name
    companyId
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    menuOptions(
      where: {option: {archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        optionId
        name
        choices(order_by: [{priority: asc}, {choiceId: asc}]) {
          id
          choiceId
          name
          costPrice
          winboardMenus(where: {_menuId: {_eq: $menuId}}) {
            id
            name
            code
            bumonCode
            bumonName
            categoryCode
            categoryName
            choiceId
            _choiceId
          }
        }
      }
    }
  }
}
    `;
export type WinboardMenuOptionsGetMenuQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
}>;


export type WinboardMenuOptionsGetMenuQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'menuId' | 'name' | 'companyId' | 'totalDealingShopCount'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, menuOptions: Array<(
      { __typename?: 'menuOption' }
      & { option: (
        { __typename?: 'option' }
        & Pick<Types.Option, 'optionId' | 'name'>
        & { choices: Array<(
          { __typename?: 'choice' }
          & Pick<Types.Choice, 'id' | 'choiceId' | 'name' | 'costPrice'>
          & { winboardMenus: Array<(
            { __typename?: 'winboardMenu' }
            & Pick<Types.WinboardMenu, 'id' | 'name' | 'code' | 'bumonCode' | 'bumonName' | 'categoryCode' | 'categoryName' | 'choiceId' | '_choiceId'>
          )> }
        )> }
      ) }
    )> }
  )> }
);


export const WinboardMenuOptionsGetMenuDocument = gql`
    query WinboardMenuOptionsGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    name
    companyId
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    menuOptions(
      where: {option: {archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        optionId
        name
        choices(order_by: [{priority: asc}, {choiceId: asc}]) {
          id
          choiceId
          name
          costPrice
          winboardMenus(where: {_menuId: {_eq: $menuId}}) {
            id
            name
            code
            bumonCode
            bumonName
            categoryCode
            categoryName
            choiceId
            _choiceId
          }
        }
      }
    }
  }
}
    `;

/**
 * __useWinboardMenuOptionsGetMenuQuery__
 *
 * To run a query within a React component, call `useWinboardMenuOptionsGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinboardMenuOptionsGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinboardMenuOptionsGetMenuQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function useWinboardMenuOptionsGetMenuQuery(baseOptions: Apollo.QueryHookOptions<WinboardMenuOptionsGetMenuQuery, WinboardMenuOptionsGetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinboardMenuOptionsGetMenuQuery, WinboardMenuOptionsGetMenuQueryVariables>(WinboardMenuOptionsGetMenuDocument, options);
      }
export function useWinboardMenuOptionsGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinboardMenuOptionsGetMenuQuery, WinboardMenuOptionsGetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinboardMenuOptionsGetMenuQuery, WinboardMenuOptionsGetMenuQueryVariables>(WinboardMenuOptionsGetMenuDocument, options);
        }
export type WinboardMenuOptionsGetMenuQueryHookResult = ReturnType<typeof useWinboardMenuOptionsGetMenuQuery>;
export type WinboardMenuOptionsGetMenuLazyQueryHookResult = ReturnType<typeof useWinboardMenuOptionsGetMenuLazyQuery>;
export type WinboardMenuOptionsGetMenuQueryResult = Apollo.QueryResult<WinboardMenuOptionsGetMenuQuery, WinboardMenuOptionsGetMenuQueryVariables>;