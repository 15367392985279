import React, { memo } from "react";
import { FormListFieldData } from "antd/lib";
import { ColumnsType } from "antd/lib/table";

import { FormList, withFormDependencies } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";
import { Table } from "components/Table";

import { Choice, WinboardMenu } from "../../types";
import { EditOptionWinboardOptionFormItem } from "../useEditOptionWinboardOptionForm";

type Props = {
  loading?: boolean;
  winboardMenus: Pick<WinboardMenu, "id" | "name" | "code" | "choiceId" | "_choiceId">[];
};

type FormListFieldDataSource = {
  name: FormListFieldData["name"];
  key: FormListFieldData["key"];
};

type rowItem = Pick<Choice, "id" | "name" | "choiceId"> & {
  winboardMenus: Array<
    Pick<
      WinboardMenu,
      "id" | "code" | "name" | "bumonCode" | "bumonName" | "categoryCode" | "categoryName"
    >
  >;
};

export const WinboardOptionChoiceTable = memo<Props>(({ loading, winboardMenus }) => {
  const columns: ColumnsType<FormListFieldDataSource> = [
    {
      title: "選択肢名",
      key: "name",
      fixed: "left",
      width: 150,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionWinboardOptionFormItem.NonProperty
            noStyle
            shouldUpdate={withFormDependencies(({ option }) => [
              option?.choices.map((choice) => choice.name),
            ])}
          >
            {({ getFieldValue }) => {
              const choiceName = getFieldValue(["option", "choices", field.name, "name"]);

              return <span>{choiceName}</span>;
            }}
          </EditOptionWinboardOptionFormItem.NonProperty>
        );
      },
    },
    {
      title: "メニューコード",
      key: "code",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionWinboardOptionFormItem.NonProperty noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <EditOptionWinboardOptionFormItem
                name={[field.name, "winboardMenus", 0, "code"]}
                rules={[
                  {
                    required: true,
                    pattern: /^\d{1,8}$/,
                    message: "8桁以内の数字で入力してください",
                  },
                  {
                    validator: async (_, value) => {
                      if (!value) return;

                      const choices: rowItem[] = getFieldValue(["option", "choices"]) || [];
                      const currentChoice = choices[field.name];

                      const conflictingExistingWinboardMenu = winboardMenus.find(
                        ({ code, choiceId, _choiceId }) =>
                          code === value
                            ? choiceId !== currentChoice?.id &&
                              _choiceId !== currentChoice?.choiceId
                            : false,
                      );

                      if (conflictingExistingWinboardMenu) {
                        const editingWinboardMenu = choices.find(
                          ({ winboardMenus }) =>
                            winboardMenus[0]?.code !== value &&
                            winboardMenus[0]?.id === conflictingExistingWinboardMenu.id,
                        );

                        if (!editingWinboardMenu) {
                          throw new Error(
                            `既存のコードと競合しています (${conflictingExistingWinboardMenu.name})`,
                          );
                        }
                      }

                      const conflictingEditingWinboardMenu = choices.filter(
                        (choice) =>
                          choice.winboardMenus[0]?.code === value &&
                          choice.choiceId !== currentChoice?.choiceId,
                      );

                      if (conflictingEditingWinboardMenu[0]) {
                        throw new Error(
                          `既存のコードと競合しています (${
                            conflictingEditingWinboardMenu[0].winboardMenus[0]?.name ??
                            conflictingEditingWinboardMenu[0].name
                          })`,
                        );
                      }
                    },
                  },
                ]}
                endSpacer={null}
              >
                <InputCode />
              </EditOptionWinboardOptionFormItem>
            )}
          </EditOptionWinboardOptionFormItem.NonProperty>
        );
      },
    },
    {
      title: "メニュー名",
      key: "name",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionWinboardOptionFormItem
            name={[field.name, "winboardMenus", 0, "name"]}
            rules={[{ required: true, max: 30, message: "30文字以内で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionWinboardOptionFormItem>
        );
      },
    },
    {
      title: "部門コード",
      key: "bumonCode",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionWinboardOptionFormItem
            name={[field.name, "winboardMenus", 0, "bumonCode"]}
            rules={[{ pattern: /^\d{1,8}$/, message: "8桁以内の数字で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionWinboardOptionFormItem>
        );
      },
    },
    {
      title: "部門名",
      key: "bumonName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionWinboardOptionFormItem
            name={[field.name, "winboardMenus", 0, "bumonName"]}
            rules={[{ max: 15, message: "15文字以内で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionWinboardOptionFormItem>
        );
      },
    },
    {
      title: "分類コード",
      key: "categoryCode",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionWinboardOptionFormItem
            name={[field.name, "winboardMenus", 0, "categoryCode"]}
            rules={[{ pattern: /^\d{1,8}$/, message: "8桁以内の数字で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionWinboardOptionFormItem>
        );
      },
    },
    {
      title: "分類名",
      key: "categoryName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionWinboardOptionFormItem
            name={[field.name, "winboardMenus", 0, "categoryName"]}
            rules={[{ max: 15, message: "15文字以内で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionWinboardOptionFormItem>
        );
      },
    },
  ];

  return (
    <FormList name={["option", "choices"]}>
      {(optionChoiceFields) => (
        <Table
          rowKey="key"
          columns={columns}
          dataSource={optionChoiceFields}
          loading={loading}
          bordered
          pagination={false}
        />
      )}
    </FormList>
  );
});
