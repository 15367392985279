import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { WarningOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { formatPrice } from "util/price";

import { Spacer } from "components/Spacer";
import { Table } from "components/Table";
import { colors } from "constants/colors";
import { usePagination } from "hooks/usePagination";

import { PaymentHistoryType } from "..";

type Props = {
  loading: boolean;
  paymentHistories: PaymentHistoryType[];
  getShouldShowWarning: ({ createdAt }: Pick<PaymentHistoryType, "createdAt">) => boolean;
};

const TransactionAtWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledWarningOutlined = styled(WarningOutlined)`
  color: ${colors.Text.Secondary};
`;

export const AdyenTerminalPaymentHistoryTable = memo<Props>(
  ({ loading, paymentHistories, getShouldShowWarning }) => {
    const [pagination, setPagination] = usePagination();

    const columns = useMemo<ColumnsType<PaymentHistoryType>>(
      () => [
        {
          title: "取引ID",
          align: "center",
          width: 100,
          dataIndex: "pspReference",
        },
        {
          title: "会計日時",
          align: "center",
          width: 100,
          render(_: string, { createdAt }) {
            return (
              <TransactionAtWrapper>
                {dayjs(createdAt).format("YYYY-MM-DD HH:mm")}
                {getShouldShowWarning({ createdAt }) ? (
                  <>
                    <Spacer size={4} />
                    <Tooltip title="こちらの取引は、入金が次のサイクルになる可能性があります。">
                      <StyledWarningOutlined />
                    </Tooltip>
                  </>
                ) : null}
              </TransactionAtWrapper>
            );
          },
        },
        {
          title: "売上",
          align: "center",
          width: 100,
          render(_: string, { amount }) {
            return formatPrice(amount);
          },
        },
        {
          title: "手数料率",
          align: "center",
          width: 100,
          render(_: string, { transactionFeeRate }) {
            return transactionFeeRate && `${transactionFeeRate}%`;
          },
        },
        {
          title: "入金額",
          align: "center",
          width: 100,
          render(_: string, { netAmount }) {
            return netAmount && formatPrice(netAmount);
          },
        },
        {
          title: "手数料",
          align: "center",
          width: 100,
          render(_: string, { feeAmount }) {
            return feeAmount && formatPrice(feeAmount);
          },
        },
        {
          title: "支払方法",
          align: "center",
          width: 100,
          render(_: string, { paymentMethod }) {
            return paymentMethod;
          },
        },
        {
          title: "取り消し",
          align: "center",
          width: 100,
          render(_: string, { adyenPaymentReportTerminalPaymentCanceledEvent }) {
            return adyenPaymentReportTerminalPaymentCanceledEvent ? (
              <Tag color="red">取り消し済み</Tag>
            ) : (
              ""
            );
          },
        },
        {
          title: "取消日時",
          align: "center",
          width: 100,
          render(_: string, { adyenPaymentReportTerminalPaymentCanceledEvent }) {
            const canceledAt = adyenPaymentReportTerminalPaymentCanceledEvent?.createdAt;
            return canceledAt ? dayjs(canceledAt).format("HH:mm") : "";
          },
        },
      ],
      [getShouldShowWarning],
    );

    return (
      <Table
        rowKey="pspReference"
        columns={columns}
        dataSource={paymentHistories}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
