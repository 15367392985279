import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { WinboardMenu } from "../../types";
import { EditWinboardMenuFormItem } from "../useEditWinboardMenuForm";

type Props = Omit<FormItemProps, "children" | "name"> & {
  winboardMenus: Pick<WinboardMenu, "id" | "name" | "code">[];
  winboardMenuId?: string;
};

export const CodeField = memo<Props>(({ winboardMenus, winboardMenuId, ...props }) => (
  <EditWinboardMenuFormItem
    label="メニューコード"
    name="code"
    rules={[
      { required: true, pattern: /^\d{1,8}$/, message: "8桁以内の数字で入力してください" },
      {
        validator: async (_, value) => {
          if (!value) return;

          const conflictingExistingWinboardMenu = winboardMenus.find(
            ({ id, code }) => code === value && id !== winboardMenuId,
          );

          if (conflictingExistingWinboardMenu) {
            throw new Error(
              `既存のコードと競合しています (${conflictingExistingWinboardMenu.name})`,
            );
          }
        },
      },
    ]}
    {...props}
  >
    <InputCode />
  </EditWinboardMenuFormItem>
));
