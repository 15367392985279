import React, { Dispatch, memo, SetStateAction } from "react";

import { ShopForAdyenStoreMerchantCategoryChangeRequestCsv } from "../types";

import { AdyenStoreMerchantCategoryChangeRequestFormModal } from "./AdyenStoreMerchantCategoryChangeRequestFormModal";
import { AdyenStoreMerchantCategoryChangeRequestSuccessModal } from "./AdyenStoreMerchantCategoryChangeRequestSuccessModal";
import { AdyenStoreMerchantCategoryChangeRequestVerifyModal } from "./AdyenStoreMerchantCategoryChangeRequestVerifyModal";
import { useAdyenStoreMerchantCategoryChangeRequestForm } from "./useAdyenStoreMerchantCategoryChangeRequestForm";

type Props = {
  shops: ShopForAdyenStoreMerchantCategoryChangeRequestCsv[];
  visible: boolean;
  setAdyenStoreMerchantCategoryChangeRequestCsvShopIds: Dispatch<SetStateAction<string[]>>;
  onClose: () => void;
};

export const AdyenStoreMerchantCategoryChangeRequestModal = memo<Props>(
  ({ shops, visible, setAdyenStoreMerchantCategoryChangeRequestCsvShopIds, onClose }) => {
    const {
      step,
      adyenStoreMerchantCategoryChangeRequestFormValues,
      isValidAdyenStoreMerchantCategoryChangeRequest,
      adyenStoreMerchantCategoryChangeRequestData,
      loadingVerifyCsv,
      loadingSubmitForm,
      onSelectFiles,
      removeUploadedFile,
      onSelectScheduledDate,
      onVerifyForm,
      onSubmitForm,
    } = useAdyenStoreMerchantCategoryChangeRequestForm({
      shops,
      setAdyenStoreMerchantCategoryChangeRequestCsvShopIds,
    });

    if (step === "verify") {
      return (
        <AdyenStoreMerchantCategoryChangeRequestVerifyModal
          visible={visible}
          adyenStoreMerchantCategoryChangeRequestData={adyenStoreMerchantCategoryChangeRequestData}
          loadingSubmitForm={loadingSubmitForm}
          onSubmitForm={onSubmitForm}
          onClose={onClose}
        />
      );
    }

    if (step === "success") {
      return (
        <AdyenStoreMerchantCategoryChangeRequestSuccessModal
          visible={visible}
          adyenStoreMerchantCategoryChangeRequestFormValues={
            adyenStoreMerchantCategoryChangeRequestFormValues
          }
          onClose={onClose}
        />
      );
    }

    return (
      <AdyenStoreMerchantCategoryChangeRequestFormModal
        visible={visible}
        loadingVerifyCsv={loadingVerifyCsv}
        adyenStoreMerchantCategoryChangeRequestFormValues={
          adyenStoreMerchantCategoryChangeRequestFormValues
        }
        isValidAdyenStoreMerchantCategoryChangeRequest={
          isValidAdyenStoreMerchantCategoryChangeRequest
        }
        onSelectFiles={onSelectFiles}
        removeUploadedFile={removeUploadedFile}
        onSelectScheduledDate={onSelectScheduledDate}
        onClose={onClose}
        onNextStep={onVerifyForm}
      />
    );
  },
);
