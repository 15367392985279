import React, { memo, useCallback } from "react";
import { Input } from "antd";
import { FormListFieldData } from "antd/lib";
import { ColumnsType } from "antd/lib/table";

import { FormList, withFormDependencies } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";
import { Table } from "components/Table";

import { PikaichiMenu, PikaichiMenuPlanChoice, PlanChoice } from "../../../types";
import { PikaichiCodeAndName } from "../..";
import { EditPlanPikaichiPlanFormItem } from "../../useEditPlanPikaichiPlanForm";

type Props = {
  optionField: FormListFieldData;
  loading?: boolean;
  pikaichiMenus: Pick<PikaichiMenu, "pikaichiMenuId" | "pikaichiMenuName" | "pikaichiMenuCd">[];
  pikaichiPlanCodeAndName: PikaichiCodeAndName;
  setPikaichiPlanChoicesCodeAndName: React.Dispatch<React.SetStateAction<PikaichiCodeAndName[]>>;
};

type FormListFieldDataSource = {
  planOptionField: {
    name: FormListFieldData["name"];
    key: FormListFieldData["key"];
  };
  planChoiceField: {
    name: FormListFieldData["name"];
    key: FormListFieldData["key"];
  };
};

type rowItem = Pick<PlanChoice, "name" | "id"> & {
  pikaichiMenuPlanChoices: Array<
    Pick<PikaichiMenuPlanChoice, "pikaichiMenuId" | "planChoiceId"> & {
      pikaichiMenu: Pick<
        PikaichiMenu,
        | "pikaichiMenuId"
        | "pikaichiMenuCd"
        | "pikaichiMenuName"
        | "pikaichiBumonCd"
        | "pikaichiBumonName"
        | "pikaichiCategoryCd"
        | "pikaichiCategoryName"
      >;
    }
  >;
};

export const PikaichiPlanChoiceTable = memo<Props>(
  ({
    optionField,
    loading,
    pikaichiMenus,
    pikaichiPlanCodeAndName,
    setPikaichiPlanChoicesCodeAndName,
  }) => {
    const handleSetPikaichiPlanChoicesCodeAndName = useCallback(
      ({ key, field, value }: { key: string; field: keyof PikaichiCodeAndName; value: string }) => {
        setPikaichiPlanChoicesCodeAndName((prev) =>
          prev.map((choice) => (choice.id === key ? { ...choice, [field]: value } : choice)),
        );
      },
      [setPikaichiPlanChoicesCodeAndName],
    );

    const columns: ColumnsType<FormListFieldDataSource> = [
      {
        title: "選択肢名",
        key: "name",
        fixed: "left",
        width: 150,
        render(_: unknown, field: FormListFieldDataSource) {
          return (
            <EditPlanPikaichiPlanFormItem.NonProperty
              noStyle
              shouldUpdate={withFormDependencies(({ plan }) => [
                plan?.planOptions.map((option) => {
                  option.planChoices.map((choice) => choice.name);
                }),
              ])}
            >
              {({ getFieldValue }) => {
                const choiceName = getFieldValue([
                  "plan",
                  "planOptions",
                  field.planOptionField.name,
                  "planChoices",
                  field.planChoiceField.name,
                  "name",
                ]);

                return <span>{choiceName}</span>;
              }}
            </EditPlanPikaichiPlanFormItem.NonProperty>
          );
        },
      },
      {
        title: "メニューコード",
        key: "pikaichiMenuCd",
        align: "left",
        width: 130,
        render(_: unknown, field: FormListFieldDataSource) {
          return (
            <EditPlanPikaichiPlanFormItem.NonProperty noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <EditPlanPikaichiPlanFormItem
                  name={[
                    field.planChoiceField.name,
                    "pikaichiMenuPlanChoices",
                    0,
                    "pikaichiMenu",
                    "pikaichiMenuCd",
                  ]}
                  rules={[
                    {
                      required: true,
                      pattern: /^[1-9]\d{0,7}$/,
                      message: "8桁以内の数字で入力してください",
                    },
                    {
                      validator: async (_, value) => {
                        if (!value) return;

                        const planChoices: rowItem[] =
                          getFieldValue([
                            "plan",
                            "planOptions",
                            field.planOptionField.name,
                            "planChoices",
                          ]) || [];
                        const currentPlanChoice = planChoices[field.planChoiceField.name];

                        const conflictingExistingPikaichiMenu = pikaichiMenus.find(
                          ({ pikaichiMenuId, pikaichiMenuCd }) =>
                            pikaichiMenuCd === value &&
                            pikaichiMenuId !==
                              currentPlanChoice?.pikaichiMenuPlanChoices[0]?.pikaichiMenu
                                ?.pikaichiMenuId,
                        );

                        if (conflictingExistingPikaichiMenu) {
                          const editingPikaichiMenu = planChoices.find(
                            (planChoice) =>
                              planChoice.pikaichiMenuPlanChoices[0]?.pikaichiMenu
                                ?.pikaichiMenuCd !== value &&
                              planChoice.pikaichiMenuPlanChoices[0]?.pikaichiMenu
                                ?.pikaichiMenuId ===
                                conflictingExistingPikaichiMenu?.pikaichiMenuId,
                          );

                          if (!editingPikaichiMenu) {
                            throw new Error(
                              `既存のコードと競合しています (${conflictingExistingPikaichiMenu.pikaichiMenuName})`,
                            );
                          }
                        }

                        const conflictingEditingPikaichiMenu = planChoices.filter(
                          (planChoice) =>
                            planChoice.pikaichiMenuPlanChoices[0]?.pikaichiMenu?.pikaichiMenuCd ===
                              value && planChoice?.id !== currentPlanChoice?.id,
                        );

                        if (conflictingEditingPikaichiMenu[0]) {
                          throw new Error(
                            `既存のコードと競合しています (${
                              conflictingEditingPikaichiMenu[0].pikaichiMenuPlanChoices[0]
                                ?.pikaichiMenu?.pikaichiMenuName ??
                              conflictingEditingPikaichiMenu[0].name
                            })`,
                          );
                        }

                        if (pikaichiPlanCodeAndName.pikaichiMenuCd === value) {
                          throw new Error(
                            `既存のコードと競合しています (${pikaichiPlanCodeAndName.pikaichiMenuName})`,
                          );
                        }
                      },
                    },
                  ]}
                  endSpacer={null}
                >
                  <Input
                    onChange={(event) => {
                      const { planOptionField, planChoiceField } = field;

                      const currentPlanChoice: rowItem =
                        getFieldValue([
                          "plan",
                          "planOptions",
                          planOptionField.name,
                          "planChoices",
                          planChoiceField.name,
                        ]) || {};

                      handleSetPikaichiPlanChoicesCodeAndName({
                        key: currentPlanChoice?.id || "",
                        field: "pikaichiMenuCd",
                        value: event.target.value,
                      });
                    }}
                  />
                </EditPlanPikaichiPlanFormItem>
              )}
            </EditPlanPikaichiPlanFormItem.NonProperty>
          );
        },
      },
      {
        title: "メニュー名",
        key: "pikaichiMenuName",
        align: "left",
        width: 150,
        render(_: unknown, field: FormListFieldDataSource) {
          return (
            <EditPlanPikaichiPlanFormItem.NonProperty noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <EditPlanPikaichiPlanFormItem
                  name={[
                    field.planChoiceField.name,
                    "pikaichiMenuPlanChoices",
                    0,
                    "pikaichiMenu",
                    "pikaichiMenuName",
                  ]}
                  rules={[{ required: true, max: 25, message: "25文字以内で入力してください" }]}
                  endSpacer={null}
                >
                  <Input
                    onChange={(event) => {
                      const { planOptionField, planChoiceField } = field;

                      const currentPlanChoice: rowItem =
                        getFieldValue([
                          "plan",
                          "planOptions",
                          planOptionField.name,
                          "planChoices",
                          planChoiceField.name,
                        ]) || {};

                      handleSetPikaichiPlanChoicesCodeAndName({
                        key: currentPlanChoice?.id || "",
                        field: "pikaichiMenuName",
                        value: event.target.value,
                      });
                    }}
                  />
                </EditPlanPikaichiPlanFormItem>
              )}
            </EditPlanPikaichiPlanFormItem.NonProperty>
          );
        },
      },
      {
        title: "部門コード",
        key: "pikaichiBumonCd",
        align: "left",
        width: 100,
        render(_: unknown, field: FormListFieldDataSource) {
          return (
            <EditPlanPikaichiPlanFormItem
              name={[
                field.planChoiceField.name,
                "pikaichiMenuPlanChoices",
                0,
                "pikaichiMenu",
                "pikaichiBumonCd",
              ]}
              rules={[{ pattern: /^\d{1,8}$/, message: "8桁以内の数字で入力してください" }]}
              endSpacer={null}
            >
              <InputCode />
            </EditPlanPikaichiPlanFormItem>
          );
        },
      },
      {
        title: "部門名",
        key: "pikaichiBumonName",
        align: "left",
        width: 150,
        render(_: unknown, field: FormListFieldDataSource) {
          return (
            <EditPlanPikaichiPlanFormItem
              name={[
                field.planChoiceField.name,
                "pikaichiMenuPlanChoices",
                0,
                "pikaichiMenu",
                "pikaichiBumonName",
              ]}
              rules={[{ max: 15, message: "15文字以内で入力してください" }]}
              endSpacer={null}
            >
              <Input />
            </EditPlanPikaichiPlanFormItem>
          );
        },
      },
      {
        title: "分類コード",
        key: "pikaichiCategoryCd",
        align: "left",
        width: 100,
        render(_: unknown, field: FormListFieldDataSource) {
          return (
            <EditPlanPikaichiPlanFormItem
              name={[
                field.planChoiceField.name,
                "pikaichiMenuPlanChoices",
                0,
                "pikaichiMenu",
                "pikaichiCategoryCd",
              ]}
              rules={[{ pattern: /^\d{1,8}$/, message: "8桁以内の数字で入力してください" }]}
              endSpacer={null}
            >
              <InputCode />
            </EditPlanPikaichiPlanFormItem>
          );
        },
      },
      {
        title: "分類名",
        key: "pikaichiCategoryName",
        align: "left",
        width: 150,
        render(_: string, field: FormListFieldDataSource) {
          return (
            <EditPlanPikaichiPlanFormItem
              name={[
                field.planChoiceField.name,
                "pikaichiMenuPlanChoices",
                0,
                "pikaichiMenu",
                "pikaichiCategoryName",
              ]}
              rules={[{ max: 15, message: "15文字以内で入力してください" }]}
              endSpacer={null}
            >
              <Input />
            </EditPlanPikaichiPlanFormItem>
          );
        },
      },
    ];

    return (
      <FormList name={[optionField.name, "planChoices"]}>
        {(planChoiceFields) => (
          <Table
            rowKey="key"
            columns={columns}
            dataSource={planChoiceFields.map((field) => ({
              planOptionField: optionField,
              planChoiceField: field,
            }))}
            loading={loading}
            bordered
            pagination={false}
          />
        )}
      </FormList>
    );
  },
);
