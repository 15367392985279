import React, { memo, useCallback, useMemo } from "react";
import { Button, Col, DatePicker, Row } from "antd";
import { DownOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { ShopSelectDropdown } from "components/ShopSelector/ShopSelectDropdown";
import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { useShopSelect } from "hooks/useShopSelect";
import { useCurrentUser } from "hooks/useUser";
import { DateString, dayjsToDateString } from "libs/DateString";
import { Role } from "types/role";

export type FilterConditions = {
  from?: DateString;
  to?: DateString;
};

type Props = {
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
};

export const AdyenTerminalPaymentHistoryFilter = memo<Props>(
  ({ filterConditions, updateFilterCondition }) => {
    const handleUpdateFilterCondition = useCallback<
      UpdateFilterConditionFunctionType<FilterConditions>
    >((values) => updateFilterCondition(values), [updateFilterCondition]);

    const user = useCurrentUser();

    const { currentShopName, loading, setShop, shops } = useShopSelect();

    const { accessibleShopIds } = useCurrentUserPermissions();

    const filteredShops = useMemo(() => {
      if (user?.role === Role.ServiceAdmin) {
        return shops;
      }

      return shops.filter((shop) =>
        accessibleShopIds ? accessibleShopIds.includes(shop.shopId) : false,
      );
    }, [accessibleShopIds, shops, user?.role]);

    const range = useMemo<[dayjs.Dayjs | null, dayjs.Dayjs | null]>(
      () => [
        filterConditions.from ? dayjs(filterConditions.from) : null,
        filterConditions.to ? dayjs(filterConditions.to) : null,
      ],
      [filterConditions],
    );

    return (
      <Row gutter={[16, 16]} align="middle">
        <Col>
          <DatePicker.RangePicker
            value={range}
            onCalendarChange={(range) => {
              const startingDate = range?.[0]?.startOf("day");
              const closingDate = range?.[1]?.endOf("day");
              handleUpdateFilterCondition({
                from: startingDate ? dayjsToDateString(startingDate) : undefined,
                to: closingDate ? dayjsToDateString(closingDate) : undefined,
              });
            }}
          />
        </Col>
        <Col>
          <ShopSelectDropdown shops={filteredShops} setShop={setShop}>
            <Button loading={loading}>
              {currentShopName ?? "店舗を選択"} <DownOutlined />
            </Button>
          </ShopSelectDropdown>
        </Col>
      </Row>
    );
  },
);
