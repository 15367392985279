import React, { memo } from "react";
import { Switch } from "antd";
import { SwitchChangeEventHandler } from "antd/es/switch";

import { FormItemProps } from "components/antd/Form";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

type Props = {
  onChangeSwitch: SwitchChangeEventHandler;
} & Omit<FormItemProps, "children" | "name">;

export const ShouldPrintSalesDetailField = memo<Props>(({ onChangeSwitch, ...props }) => (
  <EditCashRegisterConfigFormItem
    label="点検・精算伝票の売上詳細情報の表示"
    name="shouldPrintSalesDetail"
    valuePropName="checked"
    {...props}
  >
    <Switch onChange={onChangeSwitch} />
  </EditCashRegisterConfigFormItem>
));
