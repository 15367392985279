import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditOptionWinboardOptionGetOption = gql`
    query EditOptionWinboardOptionGetOption($optionId: Int!) {
  option(
    where: {optionId: {_eq: $optionId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    optionId
    name
    choices(order_by: [{priority: asc}, {choiceId: asc}]) {
      id
      choiceId
      name
      winboardMenus {
        id
        name
        code
        bumonCode
        bumonName
        categoryCode
        categoryName
        choiceId
        _choiceId
      }
    }
  }
}
    `;
export const EditOptionWinboardOptionGetCode = gql`
    query EditOptionWinboardOptionGetCode($companyId: uuid!) {
  winboardMenu(where: {companyId: {_eq: $companyId}}) {
    code
    id
    name
    choiceId
    _choiceId
  }
}
    `;
export const EditOptionWinboardOptionUpdateChoices = gql`
    mutation EditOptionWinboardOptionUpdateChoices($winboardChoices: [winboardMenu_insert_input!]!) {
  insert_winboardMenu(
    objects: $winboardChoices
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, choiceId, _choiceId]}
  ) {
    affected_rows
  }
}
    `;
export type EditOptionWinboardOptionGetOptionQueryVariables = Types.Exact<{
  optionId: Types.Scalars['Int'];
}>;


export type EditOptionWinboardOptionGetOptionQuery = (
  { __typename?: 'query_root' }
  & { option: Array<(
    { __typename?: 'option' }
    & Pick<Types.Option, 'optionId' | 'name'>
    & { choices: Array<(
      { __typename?: 'choice' }
      & Pick<Types.Choice, 'id' | 'choiceId' | 'name'>
      & { winboardMenus: Array<(
        { __typename?: 'winboardMenu' }
        & Pick<Types.WinboardMenu, 'id' | 'name' | 'code' | 'bumonCode' | 'bumonName' | 'categoryCode' | 'categoryName' | 'choiceId' | '_choiceId'>
      )> }
    )> }
  )> }
);

export type EditOptionWinboardOptionGetCodeQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditOptionWinboardOptionGetCodeQuery = (
  { __typename?: 'query_root' }
  & { winboardMenu: Array<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'code' | 'id' | 'name' | 'choiceId' | '_choiceId'>
  )> }
);

export type EditOptionWinboardOptionUpdateChoicesMutationVariables = Types.Exact<{
  winboardChoices: Array<Types.WinboardMenuInsertInput> | Types.WinboardMenuInsertInput;
}>;


export type EditOptionWinboardOptionUpdateChoicesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_winboardMenu?: Types.Maybe<(
    { __typename?: 'winboardMenu_mutation_response' }
    & Pick<Types.WinboardMenuMutationResponse, 'affected_rows'>
  )> }
);


export const EditOptionWinboardOptionGetOptionDocument = gql`
    query EditOptionWinboardOptionGetOption($optionId: Int!) {
  option(
    where: {optionId: {_eq: $optionId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    optionId
    name
    choices(order_by: [{priority: asc}, {choiceId: asc}]) {
      id
      choiceId
      name
      winboardMenus {
        id
        name
        code
        bumonCode
        bumonName
        categoryCode
        categoryName
        choiceId
        _choiceId
      }
    }
  }
}
    `;

/**
 * __useEditOptionWinboardOptionGetOptionQuery__
 *
 * To run a query within a React component, call `useEditOptionWinboardOptionGetOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOptionWinboardOptionGetOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOptionWinboardOptionGetOptionQuery({
 *   variables: {
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useEditOptionWinboardOptionGetOptionQuery(baseOptions: Apollo.QueryHookOptions<EditOptionWinboardOptionGetOptionQuery, EditOptionWinboardOptionGetOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOptionWinboardOptionGetOptionQuery, EditOptionWinboardOptionGetOptionQueryVariables>(EditOptionWinboardOptionGetOptionDocument, options);
      }
export function useEditOptionWinboardOptionGetOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOptionWinboardOptionGetOptionQuery, EditOptionWinboardOptionGetOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOptionWinboardOptionGetOptionQuery, EditOptionWinboardOptionGetOptionQueryVariables>(EditOptionWinboardOptionGetOptionDocument, options);
        }
export type EditOptionWinboardOptionGetOptionQueryHookResult = ReturnType<typeof useEditOptionWinboardOptionGetOptionQuery>;
export type EditOptionWinboardOptionGetOptionLazyQueryHookResult = ReturnType<typeof useEditOptionWinboardOptionGetOptionLazyQuery>;
export type EditOptionWinboardOptionGetOptionQueryResult = Apollo.QueryResult<EditOptionWinboardOptionGetOptionQuery, EditOptionWinboardOptionGetOptionQueryVariables>;
export const EditOptionWinboardOptionGetCodeDocument = gql`
    query EditOptionWinboardOptionGetCode($companyId: uuid!) {
  winboardMenu(where: {companyId: {_eq: $companyId}}) {
    code
    id
    name
    choiceId
    _choiceId
  }
}
    `;

/**
 * __useEditOptionWinboardOptionGetCodeQuery__
 *
 * To run a query within a React component, call `useEditOptionWinboardOptionGetCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOptionWinboardOptionGetCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOptionWinboardOptionGetCodeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditOptionWinboardOptionGetCodeQuery(baseOptions: Apollo.QueryHookOptions<EditOptionWinboardOptionGetCodeQuery, EditOptionWinboardOptionGetCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOptionWinboardOptionGetCodeQuery, EditOptionWinboardOptionGetCodeQueryVariables>(EditOptionWinboardOptionGetCodeDocument, options);
      }
export function useEditOptionWinboardOptionGetCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOptionWinboardOptionGetCodeQuery, EditOptionWinboardOptionGetCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOptionWinboardOptionGetCodeQuery, EditOptionWinboardOptionGetCodeQueryVariables>(EditOptionWinboardOptionGetCodeDocument, options);
        }
export type EditOptionWinboardOptionGetCodeQueryHookResult = ReturnType<typeof useEditOptionWinboardOptionGetCodeQuery>;
export type EditOptionWinboardOptionGetCodeLazyQueryHookResult = ReturnType<typeof useEditOptionWinboardOptionGetCodeLazyQuery>;
export type EditOptionWinboardOptionGetCodeQueryResult = Apollo.QueryResult<EditOptionWinboardOptionGetCodeQuery, EditOptionWinboardOptionGetCodeQueryVariables>;
export const EditOptionWinboardOptionUpdateChoicesDocument = gql`
    mutation EditOptionWinboardOptionUpdateChoices($winboardChoices: [winboardMenu_insert_input!]!) {
  insert_winboardMenu(
    objects: $winboardChoices
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, choiceId, _choiceId]}
  ) {
    affected_rows
  }
}
    `;
export type EditOptionWinboardOptionUpdateChoicesMutationFn = Apollo.MutationFunction<EditOptionWinboardOptionUpdateChoicesMutation, EditOptionWinboardOptionUpdateChoicesMutationVariables>;

/**
 * __useEditOptionWinboardOptionUpdateChoicesMutation__
 *
 * To run a mutation, you first call `useEditOptionWinboardOptionUpdateChoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOptionWinboardOptionUpdateChoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOptionWinboardOptionUpdateChoicesMutation, { data, loading, error }] = useEditOptionWinboardOptionUpdateChoicesMutation({
 *   variables: {
 *      winboardChoices: // value for 'winboardChoices'
 *   },
 * });
 */
export function useEditOptionWinboardOptionUpdateChoicesMutation(baseOptions?: Apollo.MutationHookOptions<EditOptionWinboardOptionUpdateChoicesMutation, EditOptionWinboardOptionUpdateChoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOptionWinboardOptionUpdateChoicesMutation, EditOptionWinboardOptionUpdateChoicesMutationVariables>(EditOptionWinboardOptionUpdateChoicesDocument, options);
      }
export type EditOptionWinboardOptionUpdateChoicesMutationHookResult = ReturnType<typeof useEditOptionWinboardOptionUpdateChoicesMutation>;
export type EditOptionWinboardOptionUpdateChoicesMutationResult = Apollo.MutationResult<EditOptionWinboardOptionUpdateChoicesMutation>;
export type EditOptionWinboardOptionUpdateChoicesMutationOptions = Apollo.BaseMutationOptions<EditOptionWinboardOptionUpdateChoicesMutation, EditOptionWinboardOptionUpdateChoicesMutationVariables>;