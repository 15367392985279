import React, { memo } from "react";
import styled from "styled-components";
import { Empty, Modal } from "antd";

import { AccessibleCompanies, AccessibleShops } from "components/Layout/DashboardLayout/types";
import { viewport } from "constants/viewport";
import { useViewport } from "hooks/useViewport";

import { DisplayNotificationCategory, TransformNotification } from "..";

import { NotificationListModalContent } from "./Content";
import { NotificationListModalFooter } from "./Footer";
import { NotificationListModalHeader } from "./Header";

const StyledModal = styled(Modal)`
  padding: 0;
  position: fixed;
  right: 0;
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .ant-modal-body {
    height: 100%;
    overflow-y: scroll;
  }
  @media ${viewport.desktop} {
    top: 64px;
    margin: 8px;
    width: 364px;
    height: calc(100vh - 180px);
  }

  @media ${viewport.tablet} {
    top: 0;
    width: calc(100vw - 32px);
    height: calc(100vh - 32px);
    margin: 16px;
  }

  @media ${viewport.smartphone} {
    top: 0;
    width: calc(100vw - 32px);
    height: calc(85svh - 32px);
    margin: 16px;
  }
`;

const Nodata = styled(Empty)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  height: 100%;
`;
const NoDataLabel = styled.p`
  color: #00000073;
`;

type Props = {
  accountAccessibleCompanies: AccessibleCompanies;
  accountAccessibleShops: AccessibleShops;
  onClose: () => void;
  open: boolean;
  isUnread: boolean;
  onChangeCategory: (key: DisplayNotificationCategory) => void;
  onChangeUnreadStatus: () => void;
  notifications: TransformNotification[];
  openDetail: (notificationId: string) => void;
};
export const NotificationListModal = memo<Props>(
  ({
    accountAccessibleCompanies,
    accountAccessibleShops,
    onClose,
    open,
    isUnread,
    onChangeCategory,
    onChangeUnreadStatus,
    notifications,
    openDetail,
  }) => {
    const { isSmartphone, isDesktop } = useViewport();

    return (
      <StyledModal
        mask={isSmartphone}
        open={open}
        width={isDesktop ? "364px" : "calc(100vw - 32px)"}
        closable={false}
        onCancel={onClose}
        zIndex={1002}
        title={
          <NotificationListModalHeader
            checked={isUnread}
            handleCategoryChange={onChangeCategory}
            handleUnreadSwitch={onChangeUnreadStatus}
          />
        }
        footer={!isDesktop && <NotificationListModalFooter handleClose={onClose} />}
      >
        {notifications.length !== 0 ? (
          <NotificationListModalContent
            notifications={notifications}
            accountAccessibleCompanies={accountAccessibleCompanies}
            accountAccessibleShops={accountAccessibleShops}
            onClickHandle={openDetail}
          />
        ) : (
          <Nodata
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<NoDataLabel>通知がありません</NoDataLabel>}
          />
        )}
      </StyledModal>
    );
  },
);
