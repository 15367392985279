import React, { Fragment, memo } from "react";
import { Link } from "react-router-dom";

const urlRegex = /(https?:\/\/[^\s]+)/g;

const lineBreak = "\n" as const;

type Props = {
  message: string;
};

/**
 * NOTE: Handles line breaks and converts URLs into clickable links
 */
export const Message = memo<Props>(({ message }) => (
  <>
    {message.split(lineBreak).map((line, lineIndex) => (
      <Fragment key={lineIndex}>
        {line.split(urlRegex).map((segment, segmentIndex) =>
          urlRegex.test(segment) ? (
            <Link key={segmentIndex} to={segment} target="_blank">
              {segment}
            </Link>
          ) : (
            <Fragment key={segmentIndex}>{segment}</Fragment>
          ),
        )}

        {lineIndex < message.split(lineBreak).length - 1 && <br />}
      </Fragment>
    ))}
  </>
));
