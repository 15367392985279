import React, { memo } from "react";
import { Button, Col, Row } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { ChoiceWithWinboardMenu, WinboardMenu } from "pages/WinboardMenuOptions/types";
import { WinboardMenuInsertInput } from "types/graphql";

import { BumonCodeField } from "./BumonCodeField";
import { BumonNameField } from "./BumonNameField";
import { CategoryCodeField } from "./CategoryCodeField";
import { CategoryNameField } from "./CategoryNameField";
import { CodeField } from "./CodeField";
import { NameField } from "./NameField";
import { useEditChoiceWinboardMenuForm } from "./useEditChoiceWinboardMenuForm";

type Props = {
  choice: ChoiceWithWinboardMenu;
  winboardMenus: Pick<WinboardMenu, "id" | "name" | "code" | "choiceId" | "_choiceId">[];
  onSubmit: (args: Omit<WinboardMenuInsertInput, "companyId">) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditChoiceWinboardMenuForm = memo<Props>(
  ({ choice, winboardMenus, onClose, onSubmit, loading }) => {
    const winboardMenu = choice?.winboardMenu;

    const { form, initialValues, submit } = useEditChoiceWinboardMenuForm({
      choice,
      winboardMenu,
      onSubmit,
    });

    return (
      <Form name="winboardMenu" form={form} layout="vertical" initialValues={initialValues}>
        <Row>
          <Col span={24}>
            <CodeField winboardMenus={winboardMenus} choice={choice} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <NameField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <BumonCodeField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <BumonNameField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CategoryCodeField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CategoryNameField />
          </Col>
        </Row>

        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            保存
          </Button>
        </FormActions>
      </Form>
    );
  },
);
