import React, { memo } from "react";
import styled from "styled-components";
import { Tag } from "antd";

import { NotificationCategory, notificationCategoryLabel } from "../../useNotifications";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ImportTag = styled(Tag)`
  background-color: #1677ff;
  color: #ffffff;
  font-weight: 400;
  size: 12px;
`;

const CategoryLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  height: 100%;
  align-items: center;
`;
type Props = {
  isImportant: boolean;
  category: NotificationCategory;
};
export const Category = memo<Props>(({ isImportant, category }) => (
  <Wrapper>
    {isImportant && <ImportTag>重要</ImportTag>}
    <CategoryLabel>{notificationCategoryLabel[category]}</CategoryLabel>
  </Wrapper>
));
