import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { PikaichiMenu } from "../../types";
import { EditPlanPikaichiPlanFormItem } from "../useEditPlanPikaichiPlanForm";
import { PikaichiCodeAndName } from "..";

type Props = Omit<FormItemProps, "children" | "name"> & {
  pikaichiMenus: Pick<PikaichiMenu, "pikaichiMenuId" | "pikaichiMenuName" | "pikaichiMenuCd">[];
  pikaichiMenuId: string | undefined;
  setPikaichiPlanCodeAndName: React.Dispatch<React.SetStateAction<PikaichiCodeAndName>>;
  pikaichiPlanChoicesCodeAndName: PikaichiCodeAndName[];
};

export const PikaichiMenuCdField = memo<Props>(
  ({
    pikaichiMenus,
    pikaichiMenuId,
    setPikaichiPlanCodeAndName,
    pikaichiPlanChoicesCodeAndName,
    ...props
  }) => (
    <EditPlanPikaichiPlanFormItem
      label="メニューコード"
      name="pikaichiMenuCd"
      rules={[
        { required: true, pattern: /^[1-9]\d{0,7}$/, message: "8桁以内の数字で入力してください" },
        {
          validator: async (_, value) => {
            if (!value) return;

            const conflictingExistingPikaichiMenu = pikaichiMenus.find(
              (pikaichiMenu) =>
                pikaichiMenu.pikaichiMenuCd === value &&
                pikaichiMenu.pikaichiMenuId !== pikaichiMenuId,
            );

            if (conflictingExistingPikaichiMenu) {
              throw new Error(
                `既存のコードと競合しています (${conflictingExistingPikaichiMenu.pikaichiMenuName})`,
              );
            }

            const conflictingPikaichiPlanChoice = pikaichiPlanChoicesCodeAndName.find(
              ({ pikaichiMenuCd }) => pikaichiMenuCd === value,
            );

            if (conflictingPikaichiPlanChoice) {
              throw new Error(
                `既存のコードと競合しています (${conflictingPikaichiPlanChoice.pikaichiMenuName})`,
              );
            }
          },
        },
      ]}
      {...props}
    >
      <InputCode
        onChange={(event) =>
          setPikaichiPlanCodeAndName((p) => ({ ...p, pikaichiMenuCd: event.target.value }))
        }
      />
    </EditPlanPikaichiPlanFormItem>
  ),
);
