import React, { memo } from "react";
import styled from "styled-components";
import { Flex, Modal, Typography } from "antd";
import { green } from "@ant-design/colors";
import { CheckCircleFilled } from "@ant-design/icons";

import { Spacer } from "components/Spacer";

import { AdyenStoreMerchantCategoryChangeRequestFormValues } from "../useAdyenStoreMerchantCategoryChangeRequestForm";

type Props = {
  visible: boolean;
  adyenStoreMerchantCategoryChangeRequestFormValues: AdyenStoreMerchantCategoryChangeRequestFormValues;
  onClose: () => void;
};

const StyledCheckCircleFilled = styled(CheckCircleFilled)`
  color: ${green[5]};
  font-size: 22px;
`;

const BoldText = styled.span`
  font-weight: 500;
`;

export const AdyenStoreMerchantCategoryChangeRequestSuccessModal = memo<Props>(
  ({ visible, adyenStoreMerchantCategoryChangeRequestFormValues, onClose }) => (
    <Modal
      title={
        <Flex align="center" gap={12}>
          <StyledCheckCircleFilled />
          CSVインポートが完了しました
        </Flex>
      }
      open={visible}
      cancelText="閉じる"
      onCancel={onClose}
      okButtonProps={{ style: { display: "none" } }}
    >
      <Typography.Text>
        ファイル:{" "}
        <BoldText>
          {
            adyenStoreMerchantCategoryChangeRequestFormValues
              .adyenStoreMerchantCategoryChangeRequestFile?.name
          }
        </BoldText>
      </Typography.Text>
      <Spacer height={4} />
      <Typography.Text>
        反映日:{" "}
        <BoldText>
          {adyenStoreMerchantCategoryChangeRequestFormValues.scheduledDate?.format("YYYY/MM/DD")}
        </BoldText>
      </Typography.Text>
    </Modal>
  ),
);
